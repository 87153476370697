import { styled } from "@mui/material"

export const CreateEditApiKey = styled("div")(({ theme }) => ({
  padding: "1.5rem",

  "& .title": {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 600,
    marginBottom: "2rem",
  },

  "& .selectedScope": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "1rem",
    alignItems: "center",
    padding: ".5rem",

    "& .icon": {
      "& svg": {
        width: ".9rem",
        "& path": {
          fill: "#323844",
        },
      },
    },

    "& .name": {
      textTransform: "capitalize",
      fontSize: "0.875rem",
      width: "135px",
      color: "#272727",
      fontWeight: 400,
    },

    "& .MuiFormControlLabel-root": {
      margin: 0,

      "& .MuiTypography-root": {
        fontSize: "0.75rem",
        marginRight: ".5rem",
      },
    },
  },
}))
