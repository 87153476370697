import InfoIcon from "@mui/icons-material/Info"
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grow,
  TextField,
} from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { getErrorMessage } from "common"
import { Progress, TOASTER_TYPE, ToastMessage } from "components"
import { ApiKeyContext } from "context"
import { useFormik } from "formik"
import { IScope, scopeLists, useCurrentUser } from "helpers"
import * as React from "react"
import { ApiClient, ApiKey } from "types"
import * as Yup from "yup"
import { CreateEditApiKey as StylesWrapper } from "./styles"
import { useGetAllScopesQuery } from "./__types/GetApiScope.types"
import { useGenerateApiKeyMutation } from "./__types/CreateApiKey.types"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
})

interface Props {
  handleClose: () => void
  apiData?: ApiKey
}

export const CreateEditApiKey: React.FC<Props> = ({ handleClose, apiData }) => {
  const [selectedScope, setSelectedScope] = React.useState<IScope[]>([])
  const { loading: loading_as } = useGetAllScopesQuery()
  const { user } = useCurrentUser()
  const [generateApiKey, { loading: api_loading }] = useGenerateApiKeyMutation()
  const { setCreationCompleted, setApiKeyId, isEditMode } =
    React.useContext(ApiKeyContext)
  const savedScope = apiData?.scope?.for

  const initialValues = {
    name: apiData?.name ?? "",
    scope: "",
    description: apiData?.description ?? "",
    expires: apiData?.expires ? new Date(apiData?.expires) : null,
  }

  React.useEffect(() => {
    setSelectedScope(
      scopeLists.flatMap(
        ss =>
          savedScope
            ?.filter(ns => ss.value === ns?.name)
            .map(s => ({
              ...ss,
              value: s!.name!,
              read: Boolean(s?.read),
              write: Boolean(s?.write),
            })) ?? []
      )
    )
  }, [isEditMode, savedScope])

  const {
    values: { name, description, expires },
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (!selectedScope.length) {
        ToastMessage(
          TOASTER_TYPE.ERROR,
          "Please select at least 1 scope for this API Key."
        )
        return
      }

      const payload = {
        ...values,
        client: ApiClient.DEVELOPER,
        environment: user?.developerConsoleProfile?.environment,
        scope: {
          for: selectedScope.map(({ value, read, write }) => ({
            name: value,
            read,
            write,
          })),
        },
      }

      try {
        const { data } = await generateApiKey({
          variables: { ...payload },
        })

        if (data?.generateApiKey?.id) {
          setApiKeyId(data.generateApiKey.id)
        }

        // TODO: write data result to apollo client

        resetForm()
        setSelectedScope([])
        setSubmitting(false)

        handleClose()
        setCreationCompleted(true)
      } catch (err) {
        ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(err))
        setSubmitting(false)
      }
    },
  })

  const handleReadWrite = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
    scope: string
  ) => {
    setSelectedScope(current =>
      current.map(cs => {
        if (cs.name === scope) {
          return {
            ...cs,
            read: type === "read" && !cs.read,
            write: type === "write" && !cs.write,
          }
        }

        return cs
      })
    )
  }

  return (
    <Box>
      {(loading_as || api_loading) && (
        <Progress progressProps={{ sx: { height: "5px" } }} />
      )}
      <StylesWrapper>
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ my: 1 }}>
            <TextField
              type="text"
              name="name"
              label="API Key Name"
              placeholder="Enter API name"
              value={name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEditMode && Boolean(name)}
              error={Boolean(touched.name) && Boolean(errors.name)}
            />

            {touched.name && errors.name && (
              <FormHelperText
                sx={{
                  color: ({ palette }) => palette.error.main,
                }}
              >
                {errors.name}
              </FormHelperText>
            )}
          </FormControl>

          <Box sx={{ my: 1 }}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              size="small"
              limitTags={3}
              options={scopeLists}
              getOptionLabel={option => option.name}
              // inputValue={isEditMode ? selectedScope.map(ss => ss.name) : []}
              onChange={(event: any, newScopes) => {
                /*  Cosmetic: to enable formik validate inputs correctly. */
                setFieldValue("scope", newScopes)

                const { target } = event
                const isDeleteMode =
                  ["path", "svg"].includes(target.nodeName) ||
                  target.getAttribute("class").includes("deleteIcon")

                if (!isDeleteMode) {
                  const toInsert = newScopes[newScopes.length - 1]

                  if (isEditMode) {
                    const scopeToAdd = newScopes.reduce<IScope[]>(
                      (scopeList, sc, idx) => {
                        const scope: IScope | undefined = selectedScope.find(
                          ns => ns.value === sc.value
                        )
                        const existingSc = selectedScope.filter(
                          ss => sc.value !== ss.value
                        )

                        if (scope) {
                          scopeList[idx] = scope
                        }

                        return existingSc.concat(scopeList)
                      },
                      []
                    )
                    console.log(scopeToAdd)
                    setSelectedScope(scopeToAdd)
                  }

                  setSelectedScope(current =>
                    newScopes.length ? current.concat(toInsert) : []
                  )
                } else {
                  // We are deleting
                  setSelectedScope(
                    selectedScope.flatMap(ss =>
                      newScopes
                        .filter(ns => ss.value === ns.value)
                        .map(e => ({
                          ...e,
                          read: ss.read,
                          write: ss.write,
                        }))
                    )
                  )
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="API Key Scope"
                  placeholder="Select scope"
                />
              )}
            />
            <FormHelperText
              sx={{
                pl: 1,
                color: ({ palette }) => palette.info.dark,
              }}
            >
              <InfoIcon sx={{ mr: 1 }} />
              Select a scope to properly define boundaries for your API Key.
              Your API key will have a default <strong>READ</strong> value if no
              selection is made.
            </FormHelperText>
            {touched.scope && selectedScope.length === 0 && (
              <FormHelperText
                sx={{
                  color: ({ palette }) => palette.error.main,
                }}
              >
                Please select a scope
              </FormHelperText>
            )}
            {selectedScope && (
              <Box
                sx={{
                  my: 2,
                  px: 2,
                }}
              >
                {selectedScope.map(
                  ({ name, icon, read, write }: IScope, idx) => (
                    <Grow
                      in={Boolean(name)}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(Boolean(name) ? { timeout: 600 } : {})}
                      key={`${name}-${idx}-scope`}
                    >
                      <div className="selectedScope">
                        <div className="d-flex align-items-center flex-nowrap">
                          <span className="me-2 icon">{icon}</span>
                          <span className="name text-truncate">{name}</span>
                        </div>

                        <FormControlLabel
                          label={"Read Only"}
                          labelPlacement="start"
                          control={
                            <Switch
                              size="small"
                              name={name}
                              checked={read}
                              onChange={e => handleReadWrite(e, "read", name)}
                            />
                          }
                        />

                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={write}
                              onChange={e => handleReadWrite(e, "write", name)}
                            />
                          }
                          label={"Read-Write"}
                          labelPlacement="start"
                        />
                      </div>
                    </Grow>
                  )
                )}
              </Box>
            )}
          </Box>

          <FormControl>
            <TextField
              multiline
              rows={1}
              name="description"
              label="Description"
              value={description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                Boolean(touched.description) && Boolean(errors.description)
              }
            />

            {touched.description && errors.description && (
              <FormHelperText
                sx={{
                  color: ({ palette }) => palette.error.main,
                }}
              >
                {errors.description}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl sx={{ my: 1 }}>
            <DatePicker
              disablePast
              label="Expiry Date"
              inputFormat="dd MMM, yyyy"
              value={expires}
              onChange={newValue => {
                setFieldValue("expires", newValue)
              }}
              renderInput={params => (
                <TextField {...params} type="date" name="expires" />
              )}
            />
            <FormHelperText
              sx={{
                color: ({ palette }) => palette.info.dark,
              }}
            >
              <InfoIcon sx={{ mr: 1 }} />
              Expiry date is optional. Only provide this if you need to restrict
              access after a time period
            </FormHelperText>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 5,
            }}
          >
            <Button
              size="large"
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{ fontWeight: 600, width: "55%", fontSize: "0.876rem" }}
            >
              {isEditMode ? "Update Api Key" : "Generate New API"}
            </Button>
          </Box>
        </form>
      </StylesWrapper>
    </Box>
  )
}
