export const homeNavigationList = [
  {
    name: 'About Us',
    to: '/about-us'
  },
  {
    name: 'Partnerships',
    to: '/partnerships'
  },
  {
    name: 'Contact',
    to: '/contact'
  } 
];
