import React, { useRef, useState } from "react";
import "./devProfileFrame.style.css";
import { useNavigate } from "react-router-dom";
import { handleGetImageFile } from "../../../helpers/utils.helper";
import { FiX } from "react-icons/fi";
import { Progress } from "../Progress";
import { DASH, EDIT_CONSOLE_PROFILE_URL } from "helpers";

interface Props {
  name?: string | null;
  picture?: string | null;
  loading?: boolean;
  isEditButton?: boolean;
  children: React.ReactNode;
  type: "edit-account" | "developer" | "account";

  getImageFromChild?: (value: any) => void;
}
export const DevProfileFrame: React.FC<Props> = ({
  type,
  name,
  picture,
  loading,
  children,
  isEditButton,
  getImageFromChild,
}) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState<string | null>();

  const chooseImageToUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleGetImageFile(e).then((value) => {
      setPreviewImage(value);
      getImageFromChild && getImageFromChild(value);
    });
  };

  const removeImage = () => {
    setPreviewImage(null);
    getImageFromChild && getImageFromChild(null);
  };

  return (
    <div className="profile_frame card">
      <div className="header_layer"></div>
      <div className="avatar_frame">
        {type === "edit-account" && (
          <>
            {previewImage && (
              <FiX onClick={removeImage} className="remove_image" />
            )}

            <div className="edit text-center">
              <label
                htmlFor="profileImage"
                className={"profileImage"}
                title={"Change profile picture, max size 70kb"}
              >
                Edit
              </label>
              <input
                hidden
                type="file"
                accept="image/*"
                id={"profileImage"}
                name={"profileImage"}
                onChange={(e) => chooseImageToUpload(e)}
                ref={inputRef}
              />
            </div>
          </>
        )}

        {!picture && !previewImage && (
          <div className="avatar_placeholder">Place company logo here</div>
        )}
        {previewImage && (
          <img src={previewImage} className="img_logo" alt="dev profile" />
        )}
        {picture && !previewImage && (
          <img src={picture} className="img_logo" alt="dev profile" />
        )}
      </div>
      <div className="profile_subheading">
        <h4>{name}</h4>

        {isEditButton && (
          <button
            className="btn od_btn_outline"
            onClick={() => navigate(`${DASH}/${EDIT_CONSOLE_PROFILE_URL}`)}
          >
            {" "}
            Edit
          </button>
        )}
      </div>
      <div className="profile_frame_body">
        {loading && <Progress />}
        {children}
      </div>
    </div>
  );
};
