import { styled } from "@mui/material"

export const FooterBox = styled("footer")(({ theme }) => ({
  backgroundColor: "#00080F",

  "& .footerGrid": {
    height: "auto",
    padding: "1rem 2rem",
    flexDirection: "column",
    justifyContent: "center",
    color: "#F3F3F3 !important",

    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  "& .middle": {
    display: "flex",
    padding: "2rem 0",
    justifyContent: "space-between",
    alignItems: "start",

    "& a": {
      color: "inherit",
      textDecoration: "none",
    },

    //mobile phones
    "@media (max-width: 590px) and (min-width: 338px)": {
      flexDirection: "column",
      padding: "3rem",
      alignItems: "center",

      "& .MuiGrid-item": {
        width: "50%",
        marginBottom: "3rem",
        textAlign: "center",

        "&.MuiGrid-item > ul": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },

        "&.MuiGrid-item:last-child > section": {
          width: "50%",
          margin: "1rem auto",
        },
      },
    },

    //Tabs
    "@media (min-width: 720px) and (max-width: 958px)": {
      padding: "0.5rem 3rem",
    },

    //Tabs-mini
    "@media (min-width: 591px) and (max-width: 719px)": {
      padding: "0 7rem",
      // flexDirection: 'column',
      flexWrap: "wrap",

      "& .MuiGrid-item": {
        width: "50%",
        marginBottom: "3rem",

        "&.MuiGrid-item:last-child > section": {
          width: "50%",
        },
      },
    },

    //  Headers (H6)
    "& h6": {
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: "1.05rem",
      color: "#F9F9F9",
    },

    //  Grid items
    "& .MuiMenuItem-root": {
      padding: "0.5rem 0",
      color: "#919191",

      "&:hover": {
        color: "#FCFCFC",
      },
    },
  },
  "& .socials": {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 0",

    "& button": {
      padding: 0,
    },

    "@media (max-width: 590px) and (min-width: 338px)": {
      flexDirection: "row",
      padding: "0.5rem",
      marginBottom: "1em",
      width: "100% !important",
    },

    "& svg": {
      color: "#919191",

      "&:hover": {
        color: "#FCFCFC",
      },
    },
  },
  "& .address": {
    "@media (max-width: 590px) and (min-width: 338px)": {
      marginBottom: "2.5em",
    },
  },
  "& .addressTop": {
    marginBottom: "2.3rem",
  },
  "& .copyright": {
    borderTop: "2px solid #777",

    "& section": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .text": {
    color: "#888",
    paddingLeft: "2rem",
  },
  "& .language": {
    width: "8rem",
  },
}))
