import { Grid, styled } from "@mui/material"

export const NoDataBox = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  flexDirection: "column",
  height: "auto",

  [theme.breakpoints.down("xs")]: {
    minHeight: "15vh",
  },

  "& aside": {
    width: "220px",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  "& .noData": {
    paddingTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#919191",
    fontSize: "0.865rem",

    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
      textAlign: "center",
    },
  },
}))
