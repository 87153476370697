import { styled } from "@mui/material"

export const DateRangerPickerBox = styled("div")(({ theme }) => ({
  height: "40px",
  width: "320px",
  marginRight: "1em",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(0.5),
  background: theme.palette.grey[200],
  justifyContent: "space-evenly",

  "& .MuiFormControl-root": {
    margin: "0 !important",
    height: "100%",
    width: "48% !important",
  },

  "& .MuiFormControl-root .MuiOutlinedInput-input": {
    padding: "0 !important",
    height: "2.5rem",
  },

  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    padding: `${theme.spacing(4, 2)} !important`,
    height: "1rem",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },

  "& .MuiFormLabel-root": {
    color: theme.palette.grey[600],
    transform: "translate(8px, 10px) scale(1) !important",
  },

  "& .MuiInputLabel-shrink": {
    transform: "translate(1px, -14px) scale(0.75) !important",
  },
}))
