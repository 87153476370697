import { styled } from "@mui/material"

export const Ripple = styled("div")`
  width: max-content;
  height: max-content;
  border-radius: 50%;
  border: none;
  animation: ripple 2s infinite;

  .floatMain {
    position: fixed;
    bottom: 1rem;
    right: 1rem;

    svg {
      font-size: 1.876rem;
    }
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 10px #e4bebe;
    }
    100% {
      box-shadow: 0 0 0 20px #f2dbdb35;
    }
  }
  &:hover {
    animation: ripple none;
  }

  .customer_service {
    animation: rotate 10s infinite forwards;
    transition: all 0.3s ease-in-out;

    @keyframes rotate {
      0%,
      40% {
        transform: rotate(0deg);
      }
      60%,
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .customer_service:hover {
    animation: rotate none;
  }
`
