import React, { useState } from "react"
import MainRoutes from "./routes/main.routes"
import { checkOnlineStatus } from "./helpers/utils.helper"
import { CssBaseline } from "@mui/material"
import { ConnectionStatus, ThemeProvider } from "components"
import { ApiKeyProvider } from "context"

const App = () => {
  const [isInternetConnected, setIsInternetConnected] = useState<Boolean>(
    navigator.onLine
  )

  setInterval(() => {
    checkOnlineStatus().then(res => {
      setIsInternetConnected(res)
    })
  }, 2000)

  return (
    <>
      {isInternetConnected ? (
        <>
          <ThemeProvider>
            <ApiKeyProvider>
              <CssBaseline />
              <MainRoutes />
            </ApiKeyProvider>
          </ThemeProvider>
        </>
      ) : (
        <ConnectionStatus />
      )}
    </>
  )
}

export default App
