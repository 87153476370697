import { FormControl, TextField } from "@mui/material"
import { DevProfileFrame } from "components"
import { useFormik } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useDeveloperConsoleProfileQuery } from "../Business/__types/DeveloperProfile.types"
import { useUpdateDeveloperBusinessProfileMutation } from "./__types/UpdateDevConsoleProfile.types"

const SchemaValidation = Yup.object().shape({
  businessName: Yup.string().required("Business name is required"),
  businessEmail: Yup.string()
    .required("Business email is required")
    .email("Invalid email. Please check your email"),
  businessWebsite: Yup.string().required("Business website is required"),
  about: Yup.string().required("About business is required"),
  RcNo: Yup.string().required("RC number is required"),
})

export const EditBusinessProfile = () => {
  const [selectedLogo, setSelectedLogo] = useState<any>(null)
  const navigate = useNavigate()
  const { data, loading } = useDeveloperConsoleProfileQuery()
  const [onUpdateProfile, { loading: uLoading }] =
    useUpdateDeveloperBusinessProfileMutation()

  const profileDetails = data && data.getDeveloperConsoleProfile

  const initialValues = {
    businessName:
      profileDetails && profileDetails.businessName
        ? profileDetails.businessName
        : "",
    businessEmail:
      profileDetails && profileDetails.businessEmail
        ? profileDetails.businessEmail
        : "",
    businessWebsite:
      profileDetails && profileDetails.businessWebsite
        ? profileDetails.businessWebsite
        : "",
    RcNo: profileDetails && profileDetails.RcNo ? profileDetails.RcNo : "",
    about: profileDetails && profileDetails.about ? profileDetails.about : "",
    businessLogo:
      profileDetails && profileDetails.businessLogo
        ? profileDetails.businessLogo
        : "",
    oneid: profileDetails && profileDetails.oneId ? profileDetails.oneId : "",
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: SchemaValidation,
    onSubmit: async values => {
      values = {
        ...values,
        businessLogo: selectedLogo ? selectedLogo : initialValues.businessLogo,
      }

      try {
        await onUpdateProfile({
          variables: values,
        })
        navigate("/dashboard/business-profile")
      } catch (e) {}
    },
  })

  const handleGetImage = (image: string) => {
    if (image) {
      setSelectedLogo(image)
    } else {
      setSelectedLogo(profileDetails?.businessLogo)
    }
  }

  const goBack = () => window.history.back()

  return (
    <DevProfileFrame
      type="edit-account"
      isEditButton={false}
      loading={loading || uLoading}
      name={initialValues.businessName}
      getImageFromChild={handleGetImage}
      picture={initialValues.businessLogo}
    >
      <form onSubmit={handleSubmit} className="form-wrapper">
        <FormControl sx={{ my: 1 }}>
          <TextField
            required
            fullWidth
            onBlur={handleBlur}
            name={"businessName"}
            onChange={handleChange}
            label={"Business Name"}
            value={values.businessName}
            helperText={
              touched.businessName &&
              Boolean(errors.businessName) &&
              errors.businessName
            }
            error={touched.businessName && Boolean(errors.businessName)}
          />
        </FormControl>

        <FormControl sx={{ my: 1 }}>
          <TextField
            required
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            name={"businessEmail"}
            label={"Business email"}
            value={values.businessEmail}
            helperText={
              touched.businessEmail &&
              Boolean(errors.businessEmail) &&
              errors.businessEmail
            }
            error={touched.businessEmail && Boolean(errors.businessEmail)}
          />
        </FormControl>

        <FormControl sx={{ my: 1 }}>
          <TextField
            required
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            name={"businessWebsite"}
            label={"Business Website"}
            value={values.businessWebsite}
            helperText={
              touched.businessWebsite &&
              Boolean(errors.businessWebsite) &&
              errors.businessWebsite
            }
            error={touched.businessWebsite && Boolean(errors.businessWebsite)}
          />
        </FormControl>

        <FormControl sx={{ my: 1 }}>
          <TextField
            required
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            name={"RcNo"}
            label={"RC Number"}
            value={values.RcNo}
            helperText={touched.RcNo && Boolean(errors.RcNo) && errors.RcNo}
            error={touched.RcNo && Boolean(errors.RcNo)}
          />
        </FormControl>

        <div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-4">
          <button
            onClick={goBack}
            type="button"
            className={" btn od_btn_outline me-4"}
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={loading || isSubmitting}
            className={"btn od_btn_flat"}
          >
            Save
          </button>
        </div>
      </form>
    </DevProfileFrame>
  )
}
