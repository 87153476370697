import { styled } from "@mui/material";

export const SuccessCreationBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",

  "& .title": {
    fontSize: "3rem",
    fontWeight: 600,
  },
}));
