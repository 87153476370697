import { ApolloProvider } from "@apollo/client"
import React from "react"
import ReactDOM from "react-dom/client"

// react-bootstrap styling
import "bootstrap/dist/css/bootstrap.min.css"
import "toastr/build/toastr.min.css"
import "./index.css"

// import components from route
import { client } from "config"
import App from "./App"
import { UnSecuredView } from "components"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    {window.self === window.top ? (
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    ) : (
      <UnSecuredView />
    )}
  </React.StrictMode>
)
