import { DeleteApiBox } from "./deleteApi.style";
import { FormControl, Box, TextField, Typography, Button } from "@mui/material";
import * as React from "react";
import { useDeleteApiKeyMutation } from "./__types/DeleteApiKey.types";
import { Progress, TOASTER_TYPE, ToastMessage } from "../../../../components";
import { ApiKey } from "types";
import { useGetAllApiKeyQuery } from "../../Api-key-Generated/queries/__types/GetAllApiKey.types";

interface Props {
  apiData: ApiKey;
  apiIDs: string[];
  isMultipleApiKeyId?: boolean;
  handleClose: () => void;
}

export const DeleteApiKey: React.FC<Props> = ({
  handleClose,
  apiData,
  isMultipleApiKeyId = false,
  apiIDs,
}) => {
  const [isMatchApiName, setIsMatchApiName] = React.useState(false);
  const [onDelete, { data, loading }] = useDeleteApiKeyMutation();
  const { loading: ag_loading, refetch } = useGetAllApiKeyQuery();

  React.useEffect(() => {
    refetch();
  }, [data]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (apiData?.id || isMultipleApiKeyId) {
        await onDelete({
          variables: {
            ids: !isMultipleApiKeyId ? [apiData.id as string] : apiIDs,
          },
        });
        handleClose();
        ToastMessage(TOASTER_TYPE.SUCCESS, "Api key deleted!");
  
      } else {
        ToastMessage(TOASTER_TYPE.ERROR, "No Api key ID provided!");
      }
    } catch (e) {}
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    if (value === apiData?.name || value.toLocaleLowerCase() === "delete") {
      setIsMatchApiName(true);
    } else {
      setIsMatchApiName(false);
    }
  };

  return (
    <DeleteApiBox>
      {(loading || ag_loading) && <Progress />}

      <Typography variant="h5">
        You are about to delete this Api Key.
      </Typography>

      {apiData?.name ? (
        <Typography sx={{ mt: 5 }}>
          To confirm this action, please enter the name{" "}
          <code>{apiData?.name}</code> of the API-KEY.
        </Typography>
      ) : (
        <Typography sx={{ mt: 5 }}>
          To confirm this action, please type <code>delete</code> to continue.
        </Typography>
      )}

      <form onSubmit={handleSubmit}>
        <FormControl sx={{ my: 3 }}>
          <TextField
            type="text"
            name="name"
            label="Name"
            placeholder="Enter name of API-KEY"
            onChange={handleChange}
          />
        </FormControl>

        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            size="large"
            onClick={handleClose}
            sx={{
              color: ({ palette }) => palette.grey[800],
              borderColor: ({ palette }) => palette.grey[800],
              borderSize: ({ spacing }) => spacing(2),
              width: "45%",

              "&:hover": {
                color: ({ palette }) => palette.grey[900],
                borderColor: ({ palette }) => palette.grey[900],
              },
            }}
          >
            Cancel
          </Button>
          <Button
            size="large"
            sx={{
              color: ({ palette }) => palette.common.white,
              backgroundColor: ({ palette }) => palette.error.main,
              width: "45%",

              "&.Mui-disabled": {
                backgroundColor: ({ palette }) => palette.error.light,
              },

              "&:hover": {
                backgroundColor: ({ palette }) => palette.error.main,
              },
            }}
            disabled={!isMatchApiName}
            type="submit"
          >
            Delete
          </Button>
        </Box>
      </form>
    </DeleteApiBox>
  );
};
