import imageCompression from "browser-image-compression";
import { getErrorMessage } from "common";
import { TOASTER_TYPE, ToastMessage } from "components";
import { format, formatDistanceToNow } from "date-fns";
import React from "react";
import { dayList, monthList } from "./date.helper";
import { Config } from "../config";

export const capitalize = (str = "") =>
  str
    ? str
        .toLowerCase()
        .replace(/\b[a-z](?=[a-z]{1})/g, (letter) => letter.toUpperCase())
    : str;

export const cleanPath = (str: string) => {
  const cleanedString = str.split("/").slice(-1).join().replace(/-/g, " ");

  return capitalize(cleanedString);
};

export const truncate = (str = "", length = 20, ending = "...") =>
  str.length > length
    ? `${str.substring(0, length - ending.length)} ${ending}`
    : str;

export const getInitials = (fName = "", lName = "", username = "") => {
  if (!fName && !lName && !username) return "ID";

  if (!fName && !lName && username)
    return `${username.charAt(0).toUpperCase()}${username
      .charAt(1)
      .toUpperCase()}`;

  if (!username && fName && lName)
    return `${fName.charAt(0).toUpperCase()}${lName.charAt(0).toUpperCase()}`;

  return `${fName.charAt(0).toUpperCase()}${lName.charAt(0).toUpperCase()}`;
};
export const getNameInitials = (fName = "", lName = "") =>
  `${fName.charAt(0).toUpperCase()}${lName.charAt(0).toUpperCase()}`;

export const getUsernameInitials = (fName = "") =>
  `${fName.charAt(0).toUpperCase()}${fName.charAt(1).toUpperCase()}`;

/**
 * formatDate
 * Returns a moment formatted date
 * @param {Date} date
 * @param options
 * */
export const formatDate = (
  date: Date | string | number,
  options = {
    format: "DD, MM YYYY",
    fromNow: false,
  }
) => {
  if (options["format"]) {
    return format(new Date(date), options.format);
  }

  if (options["fromNow"]) {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  }

  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

interface TimestampToDateOrTimeOptions {
  format?: string;
  formatTime?: string;
  monthDayYear?: string;
  monthDay?: string;
  monthYear?: string;
  dateAndTime?: string;
  todayTime?: string;
}
/**
 * @deprecated
 * @info use date-fns
 */
export const timeStampToDateOrTime = (
  timeStamp: Date | string,
  option: TimestampToDateOrTimeOptions = {
    format: "DD, MM, YYYY",
    formatTime: "HH:MM AM/PM",
    monthDayYear: "MM, DD, YYYY",
    monthDay: "MM, DD",
    monthYear: "MM, YYYY",
    dateAndTime: "DD, MM, YYYY, HH:MM",
    todayTime: "updateTime",
  }
) => {
  const dateTimeCheckIn = new Date(timeStamp);
  const year = dateTimeCheckIn.getFullYear();
  const month = monthList[dateTimeCheckIn.getMonth()];
  const day = dayList[dateTimeCheckIn.getDay()];
  const dayOfMonth = dateTimeCheckIn.getDate();
  let hours = dateTimeCheckIn.getHours();
  let minutes: number | string = dateTimeCheckIn.getMinutes();
  let seconds: number | string = dateTimeCheckIn.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  //Convert hours to 12hours
  hours = hours > 12 ? hours % 12 : hours;
  // attached 0 if minutes is a singele number
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  if (option["format"]) {
    return `${day} ${month} ${dayOfMonth}, ${year}`;
  }
  if (option["formatTime"]) {
    return `${hours}:${minutes} ${ampm}`;
  }
  if (option["todayTime"]) {
    // console.log(`${hours}:${minutes}:${seconds} ${ampm}`);
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  }
  if (option["monthDay"]) {
    return `${month} ${dayOfMonth}`;
  }
  if (option["monthDayYear"]) {
    return `${month} ${dayOfMonth} ${year}`;
  }
  if (option["monthYear"]) {
    return `${month} ${year}`;
  }
  if (option["dateAndTime"]) {
    return `${day} ${month} ${dayOfMonth}, ${year}  ${hours}:${minutes} ${ampm}`;
  }
};

// Time that Update every second
export const activeTime = () => {
  setInterval(() => {
    timeStampToDateOrTime(new Date(), { todayTime: "updateTime" });
  }, 1000);
};

// Filter based either staff/visitor/customer that check-in that present day
interface TodayDataCountProps {
  checkIn: string | number | Date;
  isCheckedIn: boolean;
}
export const todayDataCount = (data: TodayDataCountProps[]) => {
  // Attendance check
  if (data[0].checkIn && data[0].isCheckedIn) {
    const itemCount = data.filter((item) => {
      return (
        timeStampToDateOrTime(new Date()) ===
        timeStampToDateOrTime(new Date(item.checkIn))
      );
    });
    return itemCount.length;
  } else {
    return data.length;
  }
};

// extra check to simplify search
export const checkName = (name: string, value: string) => {
  var pattern = value
    .split("")
    .map((x) => {
      return `(?=.*${x})`;
    })
    .join("");
  var regex = new RegExp(`${pattern}`, "g");
  return name.match(regex);
};

// decoded token
export const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const logoutFromApp = () => {
  localStorage.removeItem("hasBuxProfile");
  localStorage.removeItem("1D_AU");
  localStorage.removeItem("1D_AUR");
  window.location.href = `${Config.AUTH_GATEWAY_URL}?d=console&r=/`;
};

export const checkOnlineStatus = async () => {
  const networkCondition = navigator.onLine ? "online" : "offline";

  if (networkCondition === "online") {
    return true;
    // try {
    //   let online = await fetch("/1pixel.png")
    //   // online.status >= 200 && online.status < 300; // either true or false
    //   return online.ok
    // } catch (err: any) {
    //   return false
    // }
  } else {
    return false;
  }
};

export const handleGetImageFile = async ({
  target: { files },
}: React.ChangeEvent<HTMLInputElement>) => {
  try {
    const imageFile = files && files.length ? files[0] : null;
    const options = {
      maxSizeMB: 0.07,
      maxWidthOrHeight: 400,
      useWebWorker: true,
    };

    if (imageFile) {
      const compressFile = await imageCompression(imageFile, options);
      const compressedFile = await imageCompression.getDataUrlFromFile(
        compressFile
      );

      return compressedFile;
    }
  } catch (e) {
    ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(e));
  }
};

export const renderSVG = (svg: any) => {
  return svg;
};

export const getPercentageValue = (value: number, total: number) => {
  let result = Math.floor((value / total) * 100);
  if (isNaN(result)) result = 0;
  return result;
};

export const sortByMostRecentDate = (data: any): any[] => {
  /**
   * sort all data using ***dateCreated*** property in the array of object
   * dateCreated - should be formated to Date from source data
   */

  const sortedData = data.sort((a: any, b: any) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);

    return dateB.getTime() - dateA.getTime();
  });

  return sortedData;
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};
