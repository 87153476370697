import { Grid, Menu, styled } from "@mui/material"

export const HomeHeaderBox = styled(Grid)(({ theme }) => ({
  flex: 1,
  height: "4rem",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "transparent",
  position: "relative",
  zIndex: 1000000,

  "& .grow": {
    flexGrow: 1,
  },
  "& .sectionDesktop": {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  "& .sectionMobile": {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  "& .mobileMenu": {
    alignSelf: "flex-end",

    "& svg": {
      fill: "#FEFEFE",
    },
  },
}))

export const MenuBox = styled(Menu)({
  "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper": {
    top: "62px !important",
    backgroundColor: "#333",
  },

  "& li a": {
    textDecoration: "none",
    color: "#d8d8d8",
  },
})

export const AboutMenuBox = styled(Menu)({
  "& .MuiPopover-paper": {
    backgroundColor: "#333",
    top: "60px !important",

    "& a": {
      textDecoration: "none",
      color: "#d8d8d8",
      width: "100%",

      "&:hover": {
        color: "#ffffff",
      },
    },
  },
})
