export const DASH = ""
export const BUSINESS_PROFILE_URL = "business-profile"
export const CREATE_CONSOLE_PROFILE_URL = "create-console-profile"
export const EDIT_CONSOLE_PROFILE_URL = "edit-console-profile"
export const DEVELOPER_PROFILE_URL = "developer-profile"
export const UPDATE_DEVELOPER_PROFILE_URL = `${DASH}/update-developer-profile`
export const API_KEY_GENERATED_URL = `${DASH}/api-key-generated`
export const API_KEY_GET_BY_ID = `${DASH}/api-key-generated/:apiKeyId`
export const API_KEY_LOG = `${DASH}/api-key-logs`
export const BILLING_OVERVIEW_URL  = `${DASH}/billing`
export const INVOICE_HISTORY_URL  = `${DASH}/billing/invoice-history`
export const COST_SUMMARY_URL  = `${DASH}/billing/cost-summary`
export const MAKE_PaYMENT_URL  = `${DASH}/billing/make-payment`
export const PERFORM_LOOKUP_URL  = `${DASH}/perform-lookup`
