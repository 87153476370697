import { styled } from "@mui/material";

export const CreateBusinessBox = styled("div")({
  // "& .title": {
  //   fontWeight: 700,
  //   fontSize: "1.8rem",
  //   color: "#3F7BFF",
  // },

  "& .titleContainer": {
    marginBottom: "1.5rem",

    "& h3": {
      color: "#0B61DD",
      fontWeight: 700,
      fontSize: "1.375rem",
    },

    "& p": {
      fontSize: "1rem",
    },
  },

  "& .blueBtn": {
    color: "#ffffff",
    backgroundColor: "#3F7BFF",
  },

  "& .blueBtnOutLine": {
    color: "#3F7BFF",
    border: "1px solid  #3F7BFF",
  },

  "& .uploadBox": {
    border: "1px dashed rgba(27, 43, 71, 0.2)",
    borderRadius: "4px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    textAlign: "center",

    "& h5": {
      color: "#1B2B4799",
    },
  },

  "& .filePicker": {
    display: "inline-block",
    position: "relative",

    "& input": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
    },

    "& p": {
      cursor: "pointer",
      color: "#3F7BFF",
      fontSize: "1rem",
    },
  },

  "& .form-control.error": {
    border: "2px solid #f44336",
  },

  "& .previewImage": {
    position: "relative",

    "& img": {
      width: "90px",
      height: "90px",
      borderRadius: "7px",
    },

    "& p": {
      color: "#3F7BFF",
      cursor: "pointer",
    },
  },

  "& .imgNote": {
    color: "#939393",
  },

  "& .clearImage": {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    color: "#ffffff",
    backgroundColor: "#d90909",
    cursor: "pointer",
    width: "25px",
    height: "25px",
    padding: "4px",
    borderRadius: "50%",
  },

  "& .alert": {
    padding: "1.2rem",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    gap: "1.25rem",
    marginTop: "1rem",

    "& img, svg, .MuiCircularProgress-root": {
      minWidth: "30px",
      minHeight: "30px",
      width: "30px",
      height: "30px",
    },

    "& p": {
      fontWeight: 500,
      fontSize: "1rem",
    },

    "& a": {
      textDecoration: "underline",
      color: "#0B61DD",
      fontWeight: 700,
    },
  },

  "& .outlineAlert": {
    border: "1px solid #0B61DD",
  },

  "& .infoAleert": {
    background: "#E2EDFE",
  },

  "& .dangerAlert": {
    background: "#FEE2E2",
    color: "#C50707",
    "& p": {
      fontWeight: 600,
    },
  },

  "& .successAlert": {
    background: "#E4FBEE",
    color: "#149E4C",
    "& p": {
      fontWeight: 600,
    },
  },
});
