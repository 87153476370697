import { Dialog, styled } from "@mui/material"

export const ResendEmailVerificationBox = styled(Dialog)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  "& .dialogTitle": {
    backgroundColor: "#e2f5ff",

    "& h6, svg": {
      color: "#004d72",
    },
  },
  "& .dialogContent": {
    padding: theme.spacing(2),

    "& p": {
      color: "#626262",
    },
  },
  "& .dialogActions": {
    margin: 0,
    padding: theme.spacing(1),
  },
  "& .code": {
    color: "#227772",
    background: "#F2F2F282",
    padding: "0.3rem",
    borderRadius: "0.2rem",
  },
  "& .loading": {
    color: "#227772",
    width: "25px !important",
    height: "25px !important",
  },
}))
