import { Grid, styled } from "@mui/material"

export const NavListBox = styled(Grid)({
  display: "flex",
  justifySelf: "end",
  justifyContent: "space-evenly",
  alignItems: "center",

  "& a": {
    color: "#fffffffa",
    textDecoration: "none",
    fontWeight: 600,
    padding: "0.5rem 1.5rem",

    "&:hover": {
      color: "#ffffff",
    },
  },

  "& p": {
    color: "#fffffffa",
    textDecoration: "none",
    fontWeight: 600,
    padding: "0.5rem 1.5rem",
    cursor: "pointer",

    "& > span": {
      color: "#C1272D",
    },

    "&:hover": {
      color: "#ffffff",
    },
  },
})
