import { styled } from "@mui/material"

export const LogoBox = styled("div")(({ theme }) => ({
  "& .main": {
    width: "3rem",
    height: "3rem",
    display: "flex",

    "& img": {
      width: "100%",
      height: "100%",
    },
  },

  "& .logoHolder_min": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  "& .logoText": {
    fontFamily: "'Proxima Nova', sans-serif",
    fontSize: "1.15rem",
    fontWeight: 800,
    // color: "#001935 !important",
  },

  "& .imageHolder": {
    display: "inline-block",
    width: "1.7rem",
    height: "1.7rem",
    marginRight: ".4rem",

    "& svg": {
      width: "100%",
      height: "100%",
    },

    "& img": {
      width: "100%",
      height: "100%",
    },
  },
}))
