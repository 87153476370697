import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { TOASTER_TYPE, ToastMessage } from "components"
import { Config } from "config/Config"



const httpLink = new HttpLink({
  uri: Config.API_BASE_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("1D_AU") || ""
  const rToken = localStorage.getItem("1D_AUR") || ""

  const apiKeyHeader = localStorage.getItem('apiKeyHeader');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token && token,
      "x-refresh-token": rToken,
      "x-oneid-api-key": apiKeyHeader || ""
    },
  }
})

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    const shouldSkipErrorToast = window.location.pathname === "/"
    if (graphQLErrors) {
      if (
        graphQLErrors.some(err => err.message.includes("Login to continue"))
      ) {
        localStorage.removeItem("1D_AU")
        window.location.href = `${Config.AUTH_GATEWAY_URL}?d=console&r=${window.location.pathname}`
        return
      }
      graphQLErrors.map(err => ToastMessage(TOASTER_TYPE.ERROR, err.message))
      return
    }

    if (networkError) {
      return ToastMessage(TOASTER_TYPE.ERROR, "Network Error")
    }
  }),
  authLink,
  httpLink,
])

// Setup the apollo client
export const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})
