import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllApiKeyQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Filter>;
}>;


export type GetAllApiKeyQuery = { __typename?: 'Query', getAllApiKey?: Array<{ __typename?: 'ApiKey', id?: string | null, assigned?: string | null, expires?: any | null, name?: string | null, description?: string | null, date?: any | null, totalCount?: number | null, status?: Types.Status | null, history?: string | null, apiKey?: string | null, createdAt?: any | null, scope?: { __typename?: 'ScopeOutput', for?: Array<{ __typename?: 'ForOutput', name?: Types.ForEnum | null, read?: boolean | null, write?: boolean | null } | null> | null } | null } | null> | null };


export const GetAllApiKeyDocument = gql`
    query GetAllApiKey($filter: Filter) {
  getAllApiKey(filter: $filter) {
    id
    assigned
    scope {
      for {
        name
        read
        write
      }
    }
    expires
    name
    description
    date
    totalCount
    status
    history
    apiKey
    createdAt
  }
}
    `;

/**
 * __useGetAllApiKeyQuery__
 *
 * To run a query within a React component, call `useGetAllApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApiKeyQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllApiKeyQuery(baseOptions?: Apollo.QueryHookOptions<GetAllApiKeyQuery, GetAllApiKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllApiKeyQuery, GetAllApiKeyQueryVariables>(GetAllApiKeyDocument, options);
      }
export function useGetAllApiKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllApiKeyQuery, GetAllApiKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllApiKeyQuery, GetAllApiKeyQueryVariables>(GetAllApiKeyDocument, options);
        }
export type GetAllApiKeyQueryHookResult = ReturnType<typeof useGetAllApiKeyQuery>;
export type GetAllApiKeyLazyQueryHookResult = ReturnType<typeof useGetAllApiKeyLazyQuery>;
export type GetAllApiKeyQueryResult = Apollo.QueryResult<GetAllApiKeyQuery, GetAllApiKeyQueryVariables>;