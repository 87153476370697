import { Typography, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useNavigate, Link } from "react-router-dom";
import * as React from "react";
import { AllApiTableAction } from "./apiTableAction";
import { ForEnum } from "types";
import { OIDTooltip } from "../../../../../components";

export const ApiKeyTableHeader: GridColDef<any>[] = [
  {
    field: "apiName",
    headerName: "API Name",
    type: "string",
    minWidth: 150,
    maxWidth: 300,
    renderCell: (params: GridRenderCellParams<any>) => (
      <Typography>{params.row.apiName}</Typography>
    ),
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    minWidth: 250,
    // renderCell: (params: GridRenderCellParams<any>) => (
    //   <Typography sx={{ pl: 1 }}>{params.row.description}</Typography>
    // ),
  },
  {
    field: "scope",
    headerName: "Scope",
    type: "string",
    minWidth: 230,
    maxWidth: 400,
    renderCell: (params: GridRenderCellParams<any>) => (
      <div className="d-flex gap-3 align-items-center">
        {params.row.scope.map((scope: any) => {
          return (
            <>
              {scope.name == ForEnum.PERSONAL && (
                <OIDTooltip title={scope.name} placement="top" arrow>
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.666992 14.6665C0.666992 13.252 1.2289 11.8955 2.22909 10.8953C3.22928 9.89507 4.58584 9.33317 6.00033 9.33317C7.41481 9.33317 8.77137 9.89507 9.77156 10.8953C10.7718 11.8955 11.3337 13.252 11.3337 14.6665H0.666992ZM6.00033 8.6665C3.79033 8.6665 2.00033 6.8765 2.00033 4.6665C2.00033 2.4565 3.79033 0.666504 6.00033 0.666504C8.21033 0.666504 10.0003 2.4565 10.0003 4.6665C10.0003 6.8765 8.21033 8.6665 6.00033 8.6665Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.BILLING && (
                <OIDTooltip title={scope.name} placement="bottom" arrow>
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.77166 9.43797L6.00033 13.2093L2.22899 9.43797C1.48314 8.69208 0.975219 7.74177 0.769453 6.70721C0.563687 5.67265 0.669321 4.60031 1.073 3.62579C1.47667 2.65126 2.16026 1.81833 3.03732 1.23231C3.91437 0.646284 4.94551 0.333496 6.00033 0.333496C7.05515 0.333496 8.08628 0.646284 8.96334 1.23231C9.8404 1.81833 10.524 2.65126 10.9277 3.62579C11.3313 4.60031 11.437 5.67265 11.2312 6.70721C11.0254 7.74177 10.5175 8.69208 9.77166 9.43797ZM1.33366 13.6666H10.667V15H1.33366V13.6666Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.KYC_BVN ||
                (scope.name == ForEnum.KYC_NIN && (
                  <OIDTooltip title={scope.name} placement="top" arrow>
                    <svg
                      width="12"
                      height="16"
                      viewBox="0 0 12 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 0.666504L11.478 1.88384C11.626 1.91675 11.7584 1.99915 11.8533 2.11744C11.9482 2.23573 12 2.38285 12 2.5345V9.1925C12 9.85099 11.8373 10.4993 11.5266 11.0798C11.2158 11.6604 10.7666 12.1553 10.2187 12.5205L6 15.3332L1.78133 12.5205C1.23352 12.1553 0.784307 11.6606 0.473565 11.0801C0.162824 10.4997 0.000157571 9.85155 0 9.19317V2.5345C2.60252e-05 2.38285 0.0517586 2.23573 0.14666 2.11744C0.241561 1.99915 0.373958 1.91675 0.522 1.88384L6 0.666504ZM6 4.6665C5.7065 4.66652 5.4212 4.76337 5.18836 4.94205C4.95551 5.12073 4.78812 5.37124 4.71216 5.65474C4.63619 5.93824 4.65589 6.23889 4.76821 6.51005C4.88052 6.78121 5.07916 7.00774 5.33333 7.1545V9.99984H6.66667L6.66733 7.1545C6.92156 7.00771 7.12023 6.78112 7.23252 6.50989C7.34482 6.23867 7.36447 5.93796 7.28842 5.65442C7.21237 5.37088 7.04487 5.12037 6.81192 4.94174C6.57896 4.76312 6.29356 4.66637 6 4.6665Z"
                        fill="#0B61DD"
                      />
                    </svg>
                  </OIDTooltip>
                ))}

              {scope.name == ForEnum.NEXTOFKIN && (
                <OIDTooltip title={scope.name} placement="bottom" arrow>
                  <svg
                    width="13"
                    height="15"
                    viewBox="0 0 13 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.89433 10.4392L10.0117 10.5572L10.1303 10.4392C10.2696 10.2999 10.435 10.1894 10.617 10.114C10.799 10.0386 10.994 9.99983 11.191 9.99983C11.388 9.99983 11.583 10.0386 11.765 10.114C11.947 10.1894 12.1124 10.2999 12.2517 10.4392C12.3909 10.5785 12.5014 10.7438 12.5768 10.9258C12.6522 11.1078 12.691 11.3029 12.691 11.4998C12.691 11.6968 12.6522 11.8919 12.5768 12.0739C12.5014 12.2559 12.3909 12.4212 12.2517 12.5605L10.0117 14.7998L7.77299 12.5605C7.49169 12.2792 7.33365 11.8977 7.33365 11.4998C7.33365 11.102 7.49169 10.7205 7.77299 10.4392C8.0543 10.1579 8.43583 9.99983 8.83366 9.99983C9.23149 9.99983 9.61302 10.1579 9.89433 10.4392ZM6.00033 9.33317V14.6665H0.666992C0.667008 13.2809 1.20628 11.9497 2.17064 10.9547C3.13499 9.95973 4.44872 9.37914 5.83366 9.33584L6.00033 9.33317ZM6.00033 0.666504C8.21033 0.666504 10.0003 2.4565 10.0003 4.6665C10.0003 6.8765 8.21033 8.6665 6.00033 8.6665C3.79033 8.6665 2.00033 6.8765 2.00033 4.6665C2.00033 2.4565 3.79033 0.666504 6.00033 0.666504Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.METERNUMBERADDRESS && (
                <OIDTooltip title={scope.name} placement="top" arrow>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0257 12.8472L17.5946 16.4161L16.4161 17.5946L12.8472 14.0257C11.5639 15.0523 9.9365 15.6665 8.1665 15.6665C4.0265 15.6665 0.666504 12.3065 0.666504 8.1665C0.666504 4.0265 4.0265 0.666504 8.1665 0.666504C12.3065 0.666504 15.6665 4.0265 15.6665 8.1665C15.6665 9.9365 15.0523 11.5639 14.0257 12.8472ZM12.8823 6.49984C12.1963 4.55739 10.3445 3.1665 8.1665 3.1665C5.404 3.1665 3.1665 5.404 3.1665 8.1665H4.83317C4.83317 6.3265 6.3265 4.83317 8.1665 4.83317C8.7425 4.83317 9.28459 4.97953 9.7575 5.23706L8.99984 6.49984H12.8823ZM13.1665 8.1665H11.4998C11.4998 10.0065 10.0065 11.4998 8.1665 11.4998C7.5905 11.4998 7.0484 11.3535 6.5755 11.0959L7.33317 9.83317H5.27956H3.45075C4.13678 11.7756 5.98846 13.1665 8.1665 13.1665C10.929 13.1665 13.1665 10.929 13.1665 8.1665Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.NATIONALITY && (
                <OIDTooltip title={scope.name} placement="top" arrow>
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0H6.25467C6.37844 6.60991e-05 6.49975 0.0345888 6.60502 0.0997016C6.71028 0.164814 6.79534 0.257946 6.85067 0.368667L7.33333 1.33333H11.3333C11.5101 1.33333 11.6797 1.40357 11.8047 1.5286C11.9298 1.65362 12 1.82319 12 2V9.33333C12 9.51014 11.9298 9.67971 11.8047 9.80474C11.6797 9.92976 11.5101 10 11.3333 10H7.07867C6.95489 9.99993 6.83358 9.96541 6.72832 9.9003C6.62305 9.83519 6.53799 9.74205 6.48267 9.63133L6 8.66667H1.33333V12.6667H0V0Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.EMERGENCY && (
                <OIDTooltip title={scope.name} placement="bottom" arrow>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66674 12.3335V8.3335C1.66674 6.91901 2.22864 5.56245 3.22883 4.56226C4.22903 3.56207 5.58558 3.00016 7.00007 3.00016C8.41456 3.00016 9.77111 3.56207 10.7713 4.56226C11.7715 5.56245 12.3334 6.91901 12.3334 8.3335V12.3335H13.0001V13.6668H1.00007V12.3335H1.66674ZM3.00007 8.3335H4.3334C4.3334 7.62625 4.61436 6.94797 5.11445 6.44788C5.61455 5.94778 6.29283 5.66683 7.00007 5.66683V4.3335C5.9392 4.3335 4.92179 4.75492 4.17164 5.50507C3.4215 6.25521 3.00007 7.27263 3.00007 8.3335ZM6.3334 0.333496H7.66674V2.3335H6.3334V0.333496ZM12.1854 2.2055L13.1281 3.14816L11.7147 4.56216L10.7714 3.6195L12.1854 2.2055ZM0.87207 3.14816L1.81474 2.2055L3.22874 3.61883L2.28674 4.56283L0.87207 3.14816Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.MEDICAL && (
                <OIDTooltip title={scope.name} placement="top" arrow>
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.00035 1.01932C8.56635 -0.38668 10.9863 -0.340013 12.495 1.17132C14.003 2.68332 14.055 5.09132 12.6523 6.66199L6.99901 12.3233L1.34701 6.66199C-0.0556549 5.09132 -0.00298804 2.67932 1.50435 1.17132C3.01435 -0.338013 5.42968 -0.38868 7.00035 1.01932Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.EDUCATION && (
                <OIDTooltip title={scope.name} placement="bottom" arrow>
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3333 13.6668H2.33333C1.71449 13.6668 1.121 13.421 0.683418 12.9834C0.245833 12.5458 0 11.9523 0 11.3335V2.3335C0 1.80306 0.210714 1.29436 0.585787 0.919283C0.960859 0.54421 1.46957 0.333496 2 0.333496H11.3333C11.5101 0.333496 11.6797 0.403734 11.8047 0.528758C11.9298 0.653782 12 0.823352 12 1.00016V13.0002C12 13.177 11.9298 13.3465 11.8047 13.4716C11.6797 13.5966 11.5101 13.6668 11.3333 13.6668ZM10.6667 12.3335V10.3335H2.33333C2.06812 10.3335 1.81376 10.4389 1.62623 10.6264C1.43869 10.8139 1.33333 11.0683 1.33333 11.3335C1.33333 11.5987 1.43869 11.8531 1.62623 12.0406C1.81376 12.2281 2.06812 12.3335 2.33333 12.3335H10.6667Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.WORKEXPERIENCE && (
                <OIDTooltip title={scope.name} placement="top" arrow>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.66634 3.33317V1.33317C3.66634 1.15636 3.73658 0.98679 3.8616 0.861766C3.98663 0.736742 4.1562 0.666504 4.33301 0.666504H9.66634C9.84315 0.666504 10.0127 0.736742 10.1377 0.861766C10.2628 0.98679 10.333 1.15636 10.333 1.33317V3.33317H12.9997C13.1765 3.33317 13.3461 3.40341 13.4711 3.52843C13.5961 3.65346 13.6663 3.82303 13.6663 3.99984V13.3332C13.6663 13.51 13.5961 13.6796 13.4711 13.8046C13.3461 13.9296 13.1765 13.9998 12.9997 13.9998H0.999674C0.822863 13.9998 0.653294 13.9296 0.52827 13.8046C0.403246 13.6796 0.333008 13.51 0.333008 13.3332V3.99984C0.333008 3.82303 0.403246 3.65346 0.52827 3.52843C0.653294 3.40341 0.822863 3.33317 0.999674 3.33317H3.66634ZM10.333 4.6665V7.99984H12.333V4.6665H10.333ZM8.99967 4.6665H4.99967V7.99984H8.99967V4.6665ZM3.66634 4.6665H1.66634V7.99984H3.66634V4.6665ZM4.99967 1.99984V3.33317H8.99967V1.99984H4.99967Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}

              {scope.name == ForEnum.TRAVEL && (
                <OIDTooltip title={scope.name} placement="bottom" arrow>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.99967 0.666504V1.99984H8.99967V0.666504H10.333V1.99984H12.9997C13.1765 1.99984 13.3461 2.07008 13.4711 2.1951C13.5961 2.32012 13.6663 2.48969 13.6663 2.6665V13.3332C13.6663 13.51 13.5961 13.6796 13.4711 13.8046C13.3461 13.9296 13.1765 13.9998 12.9997 13.9998H0.999674C0.822863 13.9998 0.653294 13.9296 0.52827 13.8046C0.403246 13.6796 0.333008 13.51 0.333008 13.3332V2.6665C0.333008 2.48969 0.403246 2.32012 0.52827 2.1951C0.653294 2.07008 0.822863 1.99984 0.999674 1.99984H3.66634V0.666504H4.99967ZM12.333 5.33317H1.66634V12.6665H12.333V5.33317ZM9.02367 6.75717L9.96634 7.69984L6.66634 10.9998L4.30901 8.6425L5.25301 7.69984L6.66701 9.1145L9.02434 6.75717H9.02367Z"
                      fill="#0B61DD"
                    />
                  </svg>
                </OIDTooltip>
              )}
            </>
          );
        })}
      </div>
    ),
  },
  {
    field: "createdAt",
    headerName: "Date Created",
    type: "string",
    minWidth: 140,
  },
  {
    field: "expiryDate",
    headerName: "Expiry date",
    type: "string",
    minWidth: 140,
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    minWidth: 130,
    renderCell: (params: GridRenderCellParams<any>) => (
      <>
        {params.row.status === "active" && (
          <div className="status active-status">Active</div>
        )}
        {params.row.status === "inactive" && (
          <div className="status inactive-status">Inactive</div>
        )}
        {params.row.status === "expired" && (
          <div className="status expired-status">Expired</div>
        )}
      </>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    width: 210,
    renderCell: (params: GridRenderCellParams<any>) => {
      return <AllApiTableAction params={params} />;
    },
  },
];
