import { Button } from "@mui/material";
import { Progress } from "components/lib/Progress";
import { DataTable } from "components/lib/Table";
import { ApiKeyContext } from "context/ApiKeyProvider";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { GenerateApiKeyDialog, DeleteApiKeyDialog } from "../components";
import { SuccessCreationDialog } from "../components/CreateEditApiKey/components/SuccessCreationDialog";
import { ApiKeyFilters } from "./components/ApiKeyFilters";
import { ApiKeyTableHeader } from "./components/tableHelpers/apiKeyTableHeader";
import { ApiKeyBox } from "./main.style";
import { useGetAllApiKeyLazyQuery } from "./queries/__types/GetAllApiKey.types";
import { sortByMostRecentDate } from "helpers";
import { ConsoleEnv } from "types";

export type IApiKeyId = {
  id: string;
};

export const ApiKeyGenerated = () => {
  const [showFilterOptions, setShowFilterOptions] = React.useState(false);
  const [openDeleteApiKeyDialog, setOpenDeleteApiKeyDialog] =
    React.useState(false);
  const [selectedApiKey, setSelectedApiKey] = React.useState<string[]>([]);
  const [openGenerateApiKeyDialog, setOpenGenerateApiKeyDialog] =
    React.useState(false);
  const { creationCompleted, setCreationCompleted, filters, envMode } =
    React.useContext(ApiKeyContext);
  const [getAllApiKeys, { data, loading, error, refetch }] =
    useGetAllApiKeyLazyQuery();

  React.useEffect(() => {
    getAllApiKeys({
      variables: {
        filter: {
          ...filters,
          environment: envMode 
        },
      },
    });
  }, [filters, getAllApiKeys, envMode]);

  React.useEffect(() => {
    refetch({
      filter: {
        ...filters,
        environment: envMode 
      },
    });
  }, [openDeleteApiKeyDialog, openGenerateApiKeyDialog, envMode, filters]);

  const apiKeyRows =
    data && data.getAllApiKey
      ? data.getAllApiKey.map((ak: any) => {
          // const scope = ak?.scope?.for
          //   ?.map((f: any) => f && f.name)
          //   .join(", ")
          //   .replace(/, ([^,]*)$/, " & $1");

          const scope = ak?.scope.for?.map((f: any) => f);

          return {
            id: ak?.id,
            apiName: ak?.name,
            description: ak?.description,
            scope: scope,
            createdAt:
              ak && ak.createdAt
                ? format(parseISO(ak.createdAt), "dd MMM, yyyy")
                : "",
            expiryDate:
              ak && ak.expires
                ? format(parseISO(ak.expires), "dd MMM, yyyy")
                : "",
            status: ak?.status,
            details: ak,
            dateCreated: new Date(ak.createdAt),
          };
        })
      : [];

  const getSelectedApi = (selectedApi: string[]) =>
    setSelectedApiKey(selectedApi);

  return (
    <React.Fragment>
      <ApiKeyBox>
        {loading && <Progress progressProps={{ sx: { height: "5px" } }} />}
        <section className="filterActionSection">
          <div className="filterContainer">
            <ApiKeyFilters
              showFilterOptions={showFilterOptions}
              handleShowFilterOptions={() =>
                setShowFilterOptions(!showFilterOptions)
              }
            />
          </div>

          <Button
            size="large"
            variant="contained"
            sx={{ fontWeight: 600 }}
            onClick={() => setOpenGenerateApiKeyDialog(true)}
          >
            Generate New API
          </Button>
        </section>

        <section>
          <DataTable
            columns={ApiKeyTableHeader}
            rows={sortByMostRecentDate(apiKeyRows)}
            isCheckboxSelection={true}
            sendSelectedRowToParent={getSelectedApi}
            batchDelete={() => setOpenDeleteApiKeyDialog(true)}
            deleteType={'API'}
          />
        </section>
      </ApiKeyBox>

      <DeleteApiKeyDialog
        title={`Delete selected Api Key`}
        apiIds={selectedApiKey}
        isMultipleApiKeyId={true} // mutliple deletion - api key
        dialogProps={{
          open: openDeleteApiKeyDialog,
          maxWidth: "xs",
          onClose: () => setOpenDeleteApiKeyDialog(false),
        }}
      />

      <GenerateApiKeyDialog
        title="Create New Api Key"
        dialogProps={{
          open: openGenerateApiKeyDialog,
          maxWidth: "sm",
          onClose: () => setOpenGenerateApiKeyDialog(false),
        }}
      />

      <SuccessCreationDialog
        dialogProps={{
          open: creationCompleted,
          maxWidth: "sm",
          fullWidth: true,
          onClose: () => setCreationCompleted(false),
        }}
      />
    </React.Fragment>
  );
};
