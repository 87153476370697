import { styled } from "@mui/material";

export const ModalFrameBox = styled("div")({

    "& .titleContainer": {
        marginBottom: "1.5rem",
    
        "& h3": {
          color: "#0B61DD",
          fontWeight: 700,
          fontSize: "1.375rem",
        },
    
        "& p": {
          fontSize: "1rem",
        },
      },
      
})