import { Modal, Paper, styled } from "@mui/material"

export const ModalBox = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0,0,0, 0.65) !important",
  },
}))

export const PaperBox = styled(Paper)(({ theme }) => ({
  maxWidth: "400px",
  backgroundColor: "#ffffff",
  position: "relative",

  "& .modalHolder": {
    margin: "1rem auto",
  },

  "& .logoSection": {
    width: "20%",
    marginBottom: theme.spacing(3),

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },

  "& .loginTitle": {
    textAlign: "center",
    fontWeight: 600,
    color: "#747373",
  },

  "& .loginSubTitle": {
    color: "#91979c",
    fontSize: ".95rem",
    textAlign: "center",
    fontWeight: 600,
    margin: ".4rem 0 2rem",
  },

  "& .loggedInAcct": {
    margin: "0 0 1rem",
  },

  "& .acctHolder": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& img": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      marginRight: ".5rem",
    },
  },

  "& .initial": {
    background: "#C1272D",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: ".5rem",
    cursor: "pointer",
    "& span": {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#ffffff",
    },
  },

  "& .name": {
    "& h5": {
      fontWeight: 800,
      fontSize: "1rem",
      cursor: "pointer",
    },
    "& p": {
      fontSize: "0.8rem",
    },
  },

  "& .footerText": {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      marginRight: ".5rem",
    },
    "& h5": {
      fontSize: "1rem",
      fontWeight: 700,
    },
  },

  "& .signUp": {
    textAlign: "center",
    margin: "2.5rem auto 2rem",
    fontWeight: 600,

    "& a": {
      color: theme.palette.ctaPrimary,
      textDecoration: "none",
    },
  },

  "& .pointerCursor": {
    cursor: "pointer",
  },

  "& .close-btn": {
    margin: theme.spacing(0.5),
  },
}))
