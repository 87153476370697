import { styled } from "@mui/material";

export const DeleteApiBox = styled("div")(({ theme }) => ({
  padding: "1.5rem",
  "& .title": {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#272727",
  },

  "& .desc": {
    fontSize: "1rem",
    fontWeight: 400,
    color: "#272727",
  },

  "& .btnSection": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",

    "& .btn": {
      padding: "0.75rem 1.5rem",
      fontWeight: 600,
      width: "200px",
    },

    "& .cancelBtn": {
      border: "2px solid #001935",
      color: "#001935",
    },

    "& .deleteBtn": {
      background: "#F71C1C",
      color: "#ffffff",
    },
  },
}));
