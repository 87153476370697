import { styled } from "@mui/material"

export const UserHeaderInfoBox = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  color: "#484848",
  "& .userInfo": {
    display: "flex",
    flexDirection: "column",
  },
  "& .verification": {
    display: "flex",
    alignItems: "center",

    "& span": {
      marginLeft: "8px",
      textTransform: "lowercase",
      fontSize: "smaller",
    },
  },
  "& .verified": {
    "& svg, span": {
      color: "#33c8d8",
    },
  },
  "& .unVerified": {
    "& svg, span": {
      color: "#f50057",
    },
  },
  "& .oneId": {
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center",
    paddingTop: "0.6rem",

    "& span": {
      fontWeight: 500,
      color: "#424548",
      fontSize: "0.8rem",
      marginLeft: "0.3rem",
    },
  },
})
