import { Drawer, styled } from "@mui/material";

export const DrawerBox = styled(Drawer)(({ theme }) => ({
  background: "#F5F5F7",
  height: "100%",
  width: theme.drawerWidth,

  "& .MuiDrawer-paper": {
    // zIndex: 1000,
  },
  "& .MuiCollapse-container": {
    marginLeft: "1.2rem !important",
  },

  "& .logoPadding": {
    padding: "1rem 0.25rem 0 1.7rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  "& .listHolder": {
    margin: theme.spacing(8, 0, 0, 2),
    height: "83vh",
    position: "relative",

    "& .MuiListItem-root": {
      background: "transparent",
      padding: ".65rem .9rem",
      borderRadius: " 4px",

      "& .MuiListItemIcon-root": {
        minWidth: "unset",
        marginRight: "0.5rem",

        "& svg": {
          "& path": {
            fill: "#272727",
          },
        },
      },

      "& .MuiTypography-root": {
        fontWeight: "600 !important",
        fontSize: ".875rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "108px",
      },

      "& .comingSoonTag": {
        fontSize: "0.625rem",
        color: "#0B61DD",
        padding: "0.125rem 0.25rem",
        background: "#E2EDFE",
        borderRadius: "100px",
        display: "flex",
        flexWrap: "no-wrap",
        marginLeft: "auto",
        width: "75px",
        textAlign: "center",
      },

      "& .MuiSvgIcon-root": {
        marginLeft: "auto",
      },

      "&:hover": {
        background: "#E7F3FE",

        "& .MuiTypography-root": {
          color: "#0B61DD",
        },

        "& .MuiListItemIcon-root": {
          "& svg": {
            "& path": {
              fill: "#0B61DD",
            },
          },
        },
      },
    },
  },

  "& .listWithCHildren": {
    // backgroundColor: "transparent !important",
  },

  "& .menuList": {
    cursor: "pointer",
    // background: "transparent",
  },

  "& .listChildren": {
    marginLeft: "2rem",
    "& .MuiListItem-root": {
      padding: ".5rem 1rem",
    },
  },

  "& .itemActiveItem": {
    background: "#E7F3FE !important",
    color: "#0B61DD",

    "& .MuiListItemIcon-root": {
      "& svg": {
        "& path": {
          fill: "#0B61DD !important",
        },
      },
    },
  },

  "& .logOutItem": {
    position: "absolute",
    bottom: "0",
  },
}));
