import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateApiKeyMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  scope: Types.ScopeInput;
  client: Types.ApiClient;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  expires?: Types.InputMaybe<Types.Scalars['DateTime']>;
  environment?: Types.InputMaybe<Types.ConsoleEnv>;
}>;


export type GenerateApiKeyMutation = { __typename?: 'Mutation', generateApiKey?: { __typename?: 'ApiKey', id?: string | null, name?: string | null, description?: string | null, expires?: any | null, status?: Types.Status | null, totalCount?: number | null, apiKey?: string | null, assigned?: string | null, scope?: { __typename?: 'ScopeOutput', for?: Array<{ __typename?: 'ForOutput', name?: Types.ForEnum | null, read?: boolean | null, write?: boolean | null } | null> | null } | null } | null };


export const GenerateApiKeyDocument = gql`
    mutation GenerateApiKey($name: String!, $scope: ScopeInput!, $client: ApiClient!, $description: String, $expires: DateTime, $environment: CONSOLE_ENV) {
  generateApiKey(
    data: {name: $name, scope: $scope, client: $client, description: $description, expires: $expires, environment: $environment}
  ) {
    id
    name
    description
    expires
    status
    totalCount
    apiKey
    assigned
    scope {
      for {
        name
        read
        write
      }
    }
  }
}
    `;
export type GenerateApiKeyMutationFn = Apollo.MutationFunction<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;

/**
 * __useGenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeyMutation, { data, loading, error }] = useGenerateApiKeyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      scope: // value for 'scope'
 *      client: // value for 'client'
 *      description: // value for 'description'
 *      expires: // value for 'expires'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGenerateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>(GenerateApiKeyDocument, options);
      }
export type GenerateApiKeyMutationHookResult = ReturnType<typeof useGenerateApiKeyMutation>;
export type GenerateApiKeyMutationResult = Apollo.MutationResult<GenerateApiKeyMutation>;
export type GenerateApiKeyMutationOptions = Apollo.BaseMutationOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;