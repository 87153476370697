import CloseIcon from "@mui/icons-material/Close"
import { Box } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Toolbar from "@mui/material/Toolbar"
import { TransitionProps } from "@mui/material/transitions"
import Typography from "@mui/material/Typography"
import * as React from "react"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})

interface Props {
  title: string
  dialogProps: DialogProps
  children?: React.ReactNode
}
export const StaticDialog: React.FC<Props> = ({
  title,
  dialogProps,
  children,
}) => {
  return (
    <Dialog
      {...dialogProps}
      sx={{
        "& .MuiDialog-container": {
          justifyContent: "flex-end",
          alignItems: "flex-start",
        
        },
      }}
      PaperProps={{
        sx: {
          m: 0,
          top: 0,
          right: 0,
          maxHeight: "100vh",
          height: "100%",
          borderRadius: 0,
        },
      }}
      TransitionComponent={Transition}
      fullWidth={dialogProps?.fullWidth ?? true}
      maxWidth={dialogProps?.maxWidth ?? "xs"}
    >
      <AppBar sx={{ position: "relative" }} color="transparent" elevation={1}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h5" fontWeight={600}>
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={e => {
              dialogProps?.onClose && dialogProps.onClose(e, "backdropClick")
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          px: 2,
          pt: 2,
          position: "relative",
        }}
      >
        {children}
      </Box>
    </Dialog>
  )
}
