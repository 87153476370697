import {
  CorporateFare,
  Logout,
  Menu as MenuIcon,
  Person,
} from "@mui/icons-material"
import {
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Switch,
} from "@mui/material"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ConsoleEnv } from "types"
import NotificationIcon from "../../../assets/icons/notification-icon.svg"
import QuestionIcon from "../../../assets/icons/question-icon.svg"
import { AppMenuList } from "../../../helpers/appMenuList.helper"
import { getInitials, logoutFromApp } from "../../../helpers/utils.helper"
import { NavBox } from "./header.styles"
import { useUpdateConsoleEnvMutation } from "./__types/UpdateConsoleEnv.mutation.types"
import { ApiKeyContext } from "context/ApiKeyProvider"
import { ToastMessage, TOASTER_TYPE } from "../../../components"
import { useGetBillPeriodInvoiceQuery } from "../../../pages/Billing/Billing-Overview/queries/__types/GetBillPeriodInvoice.types"
import CurrencyFormat from "react-currency-format"

interface Props {
  onDrawerToggle: () => void
  currentUser: any
}

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return width
}

export const Header: React.FC<Props> = ({
  onDrawerToggle,
  currentUser = {},
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [windowWidth, setWindowWidth] = useState(getWindowWidth())
  const [anchorEl, setAnchorEl] = useState(null)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [apiKeyMode, setApiKeyMode] = useState<ConsoleEnv>(
    currentUser?.developerConsoleProfile?.environment || ConsoleEnv.SANDBOX
  )
  const { envMode, setEnvMode } = React.useContext(ApiKeyContext)
  const [mutate] = useUpdateConsoleEnvMutation()
  const {
    loading: est_loading,
    error: est_error,
    data: est_data,
  } = useGetBillPeriodInvoiceQuery()

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const getPageTitle = (menuData: any) => {
    let pageTile
    for (let i = 0; i < menuData.length; i++) {
      // console.log(menuData[i]);
      if (menuData[i].hasChildren && menuData[i].children.length >= 1) {
        menuData[i].children.map((menu: any) => {
          if (location.pathname.includes(menu.link)) {
            pageTile = menu.title
          }
          if (location.pathname.includes("coming-soon")) {
            pageTile = "Coming Soon"
          }
        })
      } else {
        if (location.pathname.includes(menuData[i].link)) {
          pageTile = menuData[i].title
        }
        if (location.pathname.includes("coming-soon")) {
          pageTile = "Coming Soon"
        }
      }
    }
    return pageTile
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const mutateConsoleEnv = React.useCallback(async () => {
    if (shouldUpdate) {
      try {
        await mutate({
          variables: {
            environment: apiKeyMode,
          },
        })

        ToastMessage(TOASTER_TYPE.SUCCESS, `You are now on ${apiKeyMode} mode`)
        setEnvMode(apiKeyMode)
        setShouldUpdate(false)
      } catch (err) {
        ToastMessage(
          TOASTER_TYPE.ERROR,
          `Oops! Switching mode failed. Try again`
        )
      }
    }
  }, [shouldUpdate, apiKeyMode, mutate])

  React.useEffect(() => {
    mutateConsoleEnv()
  }, [mutateConsoleEnv])

  // TODO: type event correctly
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigateToBusinessProfile = () => {
    navigate("/business-profile")
    handleClose()
  }

  const navigateToDeveloperProfile = () => {
    navigate("/developer-profile")
    handleClose()
  }

  return (
    <React.Fragment>
      <NavBox>
        <div className="d-flex align-items-center">
          <div className="logoContainer"></div>

          <section className="headerSection">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className="menuButton"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <h3 className="pageTitle">
              {/* {pageTitle} */}
              {getPageTitle(AppMenuList)}
            </h3>

            <div className="actionSection">
              <div className="costContainer d-none d-md-block">
                <span className="title me-2">Estimated Costs:</span>
                <span className="amount">
                  <CurrencyFormat
                    value={`${
                      est_data?.getBillPeriodInvoice
                        ? est_data?.getBillPeriodInvoice.amount
                        : "0"
                    }.00`}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </span>
              </div>

              <div
                className={`modeContainer ${
                  currentUser?.developerConsoleProfile?.environment ===
                  ConsoleEnv.LIVE
                    ? "liveMode"
                    : ""
                } `}
              >
                <FormControlLabel
                  sx={{
                    textTransform: "capitalize",
                  }}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={apiKeyMode === "live"}
                      onChange={async e => {
                        setShouldUpdate(true)
                        setApiKeyMode(
                          e.target.checked
                            ? ConsoleEnv.LIVE
                            : ConsoleEnv.SANDBOX
                        )
                      }}
                      inputProps={{ "aria-label": "api-key mode" }}
                    />
                  }
                  label={windowWidth >= 991.9 ? `${apiKeyMode} Mode` : ""}
                />
              </div>

              <div className="iconContainer d-none d-lg-flex">
                <img src={QuestionIcon} alt="question" />
                <img src={NotificationIcon} alt="notification" />
              </div>

              <div className="avatarContainer">
                <div
                  aria-describedby={id}
                  onClick={handleClick}
                  className="d-flex align-items-center"
                >
                  <div className="initials me-2">
                    {getInitials(currentUser?.firstName, currentUser?.lastName)}
                  </div>

                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 5.3335L0 0.333496H10L5 5.3335Z"
                      fill="#272727"
                    />
                  </svg>
                </div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="card dropCard">
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={navigateToDeveloperProfile}>
                        <Person sx={{ mr: 1 }} />
                        Developer Profile
                      </MenuItem>
                      <MenuItem onClick={navigateToBusinessProfile}>
                        <CorporateFare sx={{ mr: 1 }} />
                        Business Profile
                      </MenuItem>
                      <MenuItem onClick={logoutFromApp}>
                        <Logout sx={{ mr: 1 }} />
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </Popover>
              </div>
            </div>
          </section>
        </div>
      </NavBox>
    </React.Fragment>
  )
}
