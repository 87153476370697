import { Box, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import { ApiKeyContext } from "context"
import * as React from "react"
import { useNavigate } from "react-router-dom"
import Lottie from "react-lottie-player"
import { SuccessCreationBox } from "./successCreation.style"
import { API_KEY_GENERATED_URL } from "helpers"

import SuccessLottie from "assets/Lottie/success.json"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  dialogProps: DialogProps
}
export const SuccessCreationDialog: React.FC<Props> = ({ dialogProps }) => {
  const { apiKeyId } = React.useContext(ApiKeyContext)
  const navigate = useNavigate()

  return (
    <Dialog
      {...dialogProps}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="Api Key successfully created"
    >
      <DialogContent>
        <SuccessCreationBox>
          <Typography
            variant="h3"
            sx={{ my: 2 }}
            textAlign="center"
            className="title"
          >
            New Api Key Generated
          </Typography>

          {dialogProps.open && (
            <Lottie
              play
              loop={0} // providing 1 makes it loop 2x
              style={{
                width: "300px",
                height: "300px",
              }}
              animationData={SuccessLottie}
            />
          )}

          <DialogContentText textAlign="center" sx={{ my: 3, px: 5 }}>
            Your new API Key has been generated successfully. Click the button
            below to view the API Key details.
          </DialogContentText>

          <Box
            sx={{
              my: 2,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{ width: "60%", fontSize: "1rem" }}
              onClick={e => {
                navigate(`${API_KEY_GENERATED_URL}/${apiKeyId}`)
                dialogProps.onClose && dialogProps.onClose(e, "backdropClick")
              }}
            >
              View Details
            </Button>
          </Box>
        </SuccessCreationBox>
      </DialogContent>
    </Dialog>
  )
}
