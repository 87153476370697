export const donutOptions = {
  //   colors: ["#B3E6C7", "#F1AFAF"],
  //   labels: ["Success", "Failed"],
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  chart: {
    type: "donut",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
  noData: {
    text: "No data",
    align: "center",
    style: {
      color: "#F5F5F5",
    },
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    cssClass: "customChartTooltip",
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
};

export const lineChartOptions = {
  colors: ["#3C8EEF"],
  // labels: ["Failed"],
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
    formatter: (val: string) => {
      // console.log(val);
      let suffix = "API Calls";
      if (parseInt(val) <= 1) suffix = "API Call";
      return `${val} ${suffix}`;
    },
  },
  chart: {
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: "smooth",
  },
  grid: {
    show: false,
  },
  xaxis: {
    type: "category",

    label: {
      format: "MM",
    },
    title: {
      text: "Time Period",
      style: {
        cssClass: "axisLabel",
      },
    },
    axisBorder: {
      show: true,
    },
  },
  yaxis: {
    title: {
      text: "API Calls",
      style: {
        cssClass: "axisLabel",
      },
    },
    axisBorder: {
      show: true,
    },
  },
  tooltip: {
    x: {
      format: "MM",
    },
  },

  // responsive: [
  //   {
  //     breakpoint: 480,
  //     options: {
  //       chart: {
  //         width: 200,
  //       },
  //       legend: {
  //         position: "bottom",
  //       },
  //     },
  //   },
  // ],
};
