import { Grid, Paper } from "@mui/material";
import { Progress, TOASTER_TYPE, ToastMessage } from "components";
import { useCurrentUser } from "helpers";
import * as React from "react";
import FailedCallIcon from "../../../assets/icons/failed-icon.svg";
import SuccessCallIcon from "../../../assets/icons/success-icon.svg";
import { AppChart } from "../../../components/lib";
import { DeveloperDashboardBox } from "./main.style";

import { useGetApiKeyDashboardSummaryStatsQuery } from "./queries/__types/GetDashboardStat.types";
import { useGetUserApikeysUsageGraphLazyQuery } from "./queries/__types/GetApiKeyGraph.types";
import { donutOptions, lineChartOptions } from "helpers/analyticChart.helpers";
import { getPercentageValue } from "helpers/utils.helper";
import { monthList } from "helpers";
import { ApiKeyHistoryGraph, ConsoleEnv } from "types";
import { useGenerateApiKeyMutation } from "pages/Api-Management/components/CreateEditApiKey/__types/CreateApiKey.types";
import { ApikKeyFilter } from "./components/ApiKeyFilteer";
import { ApiKeyContext } from "../../../context";
import { useGetBillPeriodInvoiceQuery } from "../../Billing/Billing-Overview/queries/__types/GetBillPeriodInvoice.types";
import CurrencyFormat from "react-currency-format";
import { COST_SUMMARY_URL } from "../../../helpers/routePaths.helper";
import { useNavigate } from "react-router-dom";

interface IGraphData {
  x?: string | null;
  y?: number | null;
}

export const DevDashboard = () => {
  const navigate = useNavigate();
  const { loading, user } = useCurrentUser();
  const { envMode } = React.useContext(ApiKeyContext);
  const [generateApiKey, { loading: api_loading, error, data }] =
    useGenerateApiKeyMutation();
  const [
    getApiGraph,
    {
      loading: gd_loading,
      data: gd_data,
      error: gd_error,
      refetch: gd_refetch,
    },
  ] = useGetUserApikeysUsageGraphLazyQuery();
  const {
    loading: ds_loading,
    data: ds_data,
    error: ds_error,
    refetch: ds_refetch,
  } = useGetApiKeyDashboardSummaryStatsQuery({
    variables: {
      environment: envMode as ConsoleEnv,
    },
  });
  const {
    loading: est_loading,
    error: est_error,
    data: est_data,
  } = useGetBillPeriodInvoiceQuery();

  React.useEffect(() => {
    gd_refetch();
    ds_refetch({
      environment: envMode,
    });
  }, [envMode]);

  React.useEffect(() => {
    getApiGraph({
      variables: { filter: {} },
    });
  }, []);

  if (error) ToastMessage(TOASTER_TYPE.ERROR, error.message);

  const apiKey = data && data.generateApiKey ? data.generateApiKey : "";

  const apiKeyGraphData: ApiKeyHistoryGraph[] | any[] =
    gd_data && gd_data.getUserApikeysUsageGraph
      ? gd_data.getUserApikeysUsageGraph
      : [];

  const onGenerateApiKey = async () => {
    try {
      await generateApiKey();
    } catch (e) {}
  };

  const apiKeyGraphFormattedData = (data: ApiKeyHistoryGraph[]): IGraphData[] =>
    data.map((item: ApiKeyHistoryGraph) => ({
      x: item.period,
      y: item.count,
    }));

  const apiCalls = {
    series: [
      {
        name: "API calls",
        data: [...apiKeyGraphFormattedData(apiKeyGraphData)],
      },
    ],
    options: {
      ...lineChartOptions,
      xaxis: {
        ...lineChartOptions.xaxis,
        categories: [...monthList],
        labels: {
          // format: "MMM",
          formatter: function (value: any, timestamp: any) {
            return monthList[new Date(value).getMonth()]; // The formatter function overrides format property
          },
        },
      },
    },
  };

  const totalCountDonut = {
    colors: ["#B3E6C7", "#F1AFAF"],
    labels: ["Success", "Failed"],
    ...donutOptions,
  };

  const totalFailedDonut = {
    colors: ["#F1AFAF"],
    labels: ["Failed"],
    ...donutOptions,
  };

  const totalApiCount: number | any =
    ds_data && ds_data.getApiKeyDashboardSummaryStats!.apiKeysTotalCountForUser;
  const totalFailedApiCount: number | any =
    ds_data &&
    ds_data.getApiKeyDashboardSummaryStats!.apiKeysFailedCountForUser;
  const totalSuccessApiCount: number | any =
    ds_data &&
    ds_data.getApiKeyDashboardSummaryStats!.apiKeysSuccessCountForUser;

  return (
    <DeveloperDashboardBox>
      {(loading || api_loading || ds_loading || gd_loading || est_loading) && (
        <Progress />
      )}

      <div className="cardSection row mb-3">
        <div className="col-xl-4 col-lg-6 mb-4 mb-xl-0">
          <div className="dashCard">
            <h5 className="title">Total API Calls</h5>

            <div className="body">
              <div className="chart">
                <AppChart
                  options={totalCountDonut}
                  series={
                    totalApiCount >= 1
                      ? [totalSuccessApiCount, totalFailedApiCount]
                      : []
                  }
                  type={"donut"}
                  height={"200px"}
                  count={totalApiCount}
                />
              </div>
              <div className="textHolder">
                <div className="legendContainer">
                  <div className="successCall">
                    <div className="desc">Successful API Calls</div>
                    <div className="metric">
                      <div className="icon">
                        <img
                          src={SuccessCallIcon}
                          width="15"
                          height="15"
                          alt="success call"
                        />
                      </div>
                      <div className="percent">
                        {getPercentageValue(
                          totalSuccessApiCount,
                          totalApiCount
                        )}
                        %
                      </div>
                    </div>
                  </div>

                  <div className="failedCall">
                    <div className="desc">Failed API Calls</div>
                    <div className="metric">
                      <div className="icon">
                        <img
                          src={FailedCallIcon}
                          width="15"
                          height="15"
                          alt="failed call"
                        />
                      </div>
                      <div className="percent">
                        {getPercentageValue(totalFailedApiCount, totalApiCount)}
                        %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-6 mb-4 mb-xl-0">
          <div className="dashCard">
            <h5 className="title">Failed API Calls</h5>

            <div className="body">
              <div className="chart">
                <AppChart
                  options={totalFailedDonut}
                  series={totalFailedApiCount >= 1 ? [totalFailedApiCount] : []}
                  count={totalFailedApiCount}
                  type={"donut"}
                  height={"170px"}
                />
              </div>

              <div className="failedTextHolder">
                <h6>Reason(s) for API Failure</h6>
                <p className="mb-0">
                  {totalFailedApiCount && totalFailedApiCount >= 1 ? (
                    <>
                      {" "}
                      The specified cluster wasn't found. Verify the spelling of
                      the cluster name.
                    </>
                  ) : (
                    <>We gat you, You have no failed API calls</>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-12 mb-4">
          <div className="dashCard">
            <h5 className="title">Cost Estimate of Usage</h5>

            <div className="body lastBody">
              <div className="amount">
                <CurrencyFormat
                  value={`${
                    est_data?.getBillPeriodInvoice
                      ? est_data?.getBillPeriodInvoice.amount
                      : "0"
                  }.00`}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </div>
              <div className="desc">
                This is the current costs for your usage this billing period.
              </div>
              <button
                className="btn od_btn_flat"
                onClick={() => {
                  navigate(COST_SUMMARY_URL);
                }}
              >
                View Cost Breakdown
              </button>
            </div>
          </div>
        </div>

        <Grid container xs={12} sx={{ px: 1, mt: 2, ml: 0, width: "100%" }}>
          <Paper elevation={1} className="apiCallContainer">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="viewTraffic mb-0">View Traffic</h3>

              <ApikKeyFilter />
            </div>

            <AppChart
              options={apiCalls.options}
              series={apiCalls.series}
              type={"line"}
              containerHeight={"100%"}
            />
          </Paper>
        </Grid>
      </div>
    </DeveloperDashboardBox>
  );
};
