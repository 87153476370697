export const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

export const dayList = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

export const DATE_FORMAT = {
  GQL_DATE: "YYYY-MM-dd",
  GQL_TIME: "HH:mm:ss",
  GQL_DATE_TIME: "YYYY-MM-ddTHH:mm:ss",
}
