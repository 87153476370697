import { Box, styled, Typography } from "@mui/material"
import { format } from "date-fns"
import { TextLogo, TextLogoRed } from "../../lib/Logo/Logo"

const CopyrightBox = styled(Box)(({ color }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",

  "& .text": {
    color: `${color ?? "#333"}`,
    paddingLeft: "1rem",
  },
}))

interface Props {
  companyName: string
  primary: boolean
}
export const Copyright: React.FC<Props> = ({ companyName, primary }) => {
  return (
    <CopyrightBox>
      {primary ? <TextLogoRed color={"#333"} /> : <TextLogo />}

      <Typography variant="body2" align="center" className="text">
        © {format(new Date(), "y")} <span>All Rights Reserved.</span>
      </Typography>
    </CopyrightBox>
  )
}
