import { useGetProfileDataQuery } from "./__types/UserProfile.types"

export const useCurrentUser = () => {
  const { loading, error, data, refetch } = useGetProfileDataQuery()

  return {
    loading,
    error,
    refetch,
    user: data && data.userGetProfile,
  }
}
