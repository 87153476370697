import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProfileDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProfileDataQuery = { __typename?: 'Query', userGetProfile?: { __typename?: 'Profile', _id?: string | null, dob?: any | null, phone?: string | null, userId?: string | null, gender?: string | null, profileImage?: string | null, lastName?: string | null, firstName?: string | null, user?: { __typename?: 'User', email?: string | null, oneId?: string | null, username?: string | null } | null, developerConsoleProfile?: { __typename?: 'DeveloperConsoleProfile', _id?: string | null, businessName?: string | null, businessLogo?: string | null, businessEmail?: string | null, environment?: Types.ConsoleEnv | null } | null } | null };


export const GetProfileDataDocument = gql`
    query GetProfileData {
  userGetProfile {
    _id
    dob
    phone
    userId
    user {
      email
      oneId
      username
    }
    gender
    profileImage
    lastName
    firstName
    developerConsoleProfile {
      _id
      businessName
      businessLogo
      businessEmail
      environment
    }
  }
}
    `;

/**
 * __useGetProfileDataQuery__
 *
 * To run a query within a React component, call `useGetProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileDataQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileDataQuery, GetProfileDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileDataQuery, GetProfileDataQueryVariables>(GetProfileDataDocument, options);
      }
export function useGetProfileDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileDataQuery, GetProfileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileDataQuery, GetProfileDataQueryVariables>(GetProfileDataDocument, options);
        }
export type GetProfileDataQueryHookResult = ReturnType<typeof useGetProfileDataQuery>;
export type GetProfileDataLazyQueryHookResult = ReturnType<typeof useGetProfileDataLazyQuery>;
export type GetProfileDataQueryResult = Apollo.QueryResult<GetProfileDataQuery, GetProfileDataQueryVariables>;