import ReactApexChart from "react-apexcharts";
import { ChartBox } from "./appChart.style";

interface ChartProps {
  options: any;
  series: any;
  type: any;
  count?: number;
  width?: string;
  height?: string;
  containerHeight?: string;
}

export const AppChart: React.FC<ChartProps> = ({
  options,
  series,
  type,
  count,
  width = "100%",
  height = "98%",
  containerHeight
}) => {
  const renderChart = (options: any, series: any, type: any) => {
    return (
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        width={width}
        height={height}
      />
    );
  };

  return (
    <ChartBox style={{ height: containerHeight ? containerHeight : "auto" }}>
      {/* Donut empty state */}
      {type === "donut" && series.length < 1 && (
        <div className="emptyStateDonut">
          <span className="count">0</span>
          <svg
            width="151"
            height="150"
            viewBox="0 0 151 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M150.5 75C150.5 116.421 116.921 150 75.5 150C34.0786 150 0.5 116.421 0.5 75C0.5 33.5786 34.0786 0 75.5 0C116.921 0 150.5 33.5786 150.5 75ZM30.5 75C30.5 99.8528 50.6472 120 75.5 120C100.353 120 120.5 99.8528 120.5 75C120.5 50.1472 100.353 30 75.5 30C50.6472 30 30.5 50.1472 30.5 75Z"
              fill="#F5F5F5"
            />
          </svg>
        </div>
      )}

      {type === "donut" && series.length >= 1 && (
        <>
          <div className="donutCenterCount">{count}</div>
          {renderChart(options, series, type)}
        </>
      )}

      {/* For other chart */}
      {type !== "donut" && <>{renderChart(options, series, type)}</>}
    </ChartBox>
  );
};
