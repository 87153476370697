import { styled } from "@mui/material";

export const ApiKeyBox = styled("div")(({ theme }) => ({
  "& .filterActionSection": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#ffffff",
    borderRadius: "8px 8px 0 0",
    padding: "1rem",
  },

  "& .filterContainer": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },

  "& .filterChip": {
    display: "inline-flex",
    alignItems: "center",
    padding: "0.46rem 0.5rem",
    background: " #F5F5F5",
    borderRadius: "4px",
    color: " #272727",
    cursor: "pointer",
  },

  // Table style specific for this page
  "& .MuiDataGrid-root .MuiDataGrid-columnHeader": {
    padding: "0 1rem",

    "&:first-child": {
      padding: "0",
    },
  },
}));
