import { Hidden } from "@mui/material";
import { ToastMessage, TOASTER_TYPE, Progress } from "components/lib";
import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useCurrentUser } from "../../../helpers/lib/useLib.hooks";
import { Navigator } from "../Navigator";
import { BaseBox } from "./base.styles";
import { UPDATE_DEVELOPER_PROFILE_URL, MAKE_PaYMENT_URL } from "helpers";
import { CreateBusinessProfile } from "../../../pages/Create-Business/CreateBusiness";
import { Header } from "../Header";
import { theme } from "../../themes";

interface Props {
  children?: React.ReactNode;
}
export const Base: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { loading, user } = useCurrentUser();

  useEffect(() => {
    if (user) {
      return;
    } else {
      // redirect the user to update their OneID profile (Once the loader stops)
      if (!loading) {
        ToastMessage(TOASTER_TYPE.INFO, "Please update your profile");
        navigate(UPDATE_DEVELOPER_PROFILE_URL);
      }
    }
  }, [user, navigate, loading]);

  if (loading) return <Progress />;

  const handleDrawerToggle = () => {
    console.log("toggler is working");
    setMobileOpen(!mobileOpen);
  };

  return (
    <BaseBox>
      <CreateBusinessProfile />

      <div className="appContent">
        {/* {!location.pathname.includes("coming-soon") && ( */}
        <Header onDrawerToggle={handleDrawerToggle} currentUser={user} />

        {location.pathname !== MAKE_PaYMENT_URL ? (
          <aside className="drawer">
            <Hidden smUp implementation="js">
              <Navigator
                drawerProps={{
                  variant: "temporary",
                  open: mobileOpen,
                  onClose: handleDrawerToggle,
                }}
                // handleDrawerToggle={handleDrawerToggle}
              />
            </Hidden>
            <div className="desktopSidebarContainer">
              <Hidden xsDown implementation="css">
                <Navigator
                  drawerProps={{
                    variant: "permanent",
                  }}
                />
              </Hidden>
            </div>
          </aside>
        ) : null}

        {/* )} */}
        <main className="mainContent">
          {children}
          <Outlet />
        </main>
      </div>
    </BaseBox>
  );
};
