import { DevProfileFrame, Progress } from "components";
import "./businessProfile.style.css";
import { useDeveloperConsoleProfileQuery } from "./__types/DeveloperProfile.types";
import * as React from "react";

export const BusinessProfile = () => {
  const { loading, data } = useDeveloperConsoleProfileQuery();

  const profileDetails = data && data.getDeveloperConsoleProfile;

  return (
    <React.Fragment>
      {loading && <Progress />}

      <DevProfileFrame
        name={profileDetails?.businessName}
        picture={profileDetails?.businessLogo}
        isEditButton={true}
        type="account"
      >
        <div className="">
          <div className="profile_list">
            <h5 className="mb-0 me-4">Business website:</h5>
            <p className="mb-0">{profileDetails?.businessWebsite}</p>
          </div>
          <div className="profile_list">
            <h5 className="mb-0 me-4">Business email:</h5>
            <p className="mb-0">{profileDetails?.businessEmail}</p>
          </div>
          <div className="profile_list mb-0">
            <h5 className="mb-0 me-4">RC Number:</h5>
            <p className="mb-0">{profileDetails?.RcNo}</p>
          </div>
        </div>
      </DevProfileFrame>
    </React.Fragment>
  );
};
