import { DialogProps } from "@mui/material/Dialog"
import { StaticDialog } from "components"
import * as React from "react"
import { CreateEditApiKey } from "../CreateEditApiKey"

interface Props {
  title: string
  dialogProps: DialogProps
  data?: any | null
}
export const GenerateApiKeyDialog: React.FC<Props> = ({
  title,
  dialogProps,
  data,
}) => {
  return (
    <StaticDialog title={title} dialogProps={dialogProps}>
      <CreateEditApiKey
        apiData={data}
        handleClose={() => {
          dialogProps.onClose && dialogProps.onClose({}, "backdropClick")
        }}
      />
    </StaticDialog>
  )
}
