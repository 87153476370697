import { ApiKeyContext } from "context";
import * as React from "react";
import { Link } from "react-router-dom";
import { ApiKey } from "types";
import { GenerateApiKeyDialog, DeleteApiKeyDialog } from "../../../components";
import { OIDTooltip } from "components";

export const AllApiTableAction = ({ params }: any) => {
  const [openGenerateApiKeyDialog, setOpenGenerateApiKeyDialog] =
    React.useState(false);
  const [openDeleteApiKeyDialog, setOpenDeleteApiKeyDialog] =
    React.useState(false);
  const [apiData, setApiData] = React.useState<ApiKey>();
  const { setApiKeyId, setIsEditMode } = React.useContext(ApiKeyContext);

  const handleRowDelete = (item: ApiKey) => {
    // console.log(item, "delete");
    setOpenDeleteApiKeyDialog(true);
    setApiData(item);
  };

  const handleEdit = (item: ApiKey) => {
    setOpenGenerateApiKeyDialog(true);
    setApiData(item);
    setIsEditMode(true);
    // setApiKeyId(item.id)
  };

  // console.log(params)

  return (
    <div className="d-flex g-4 align-items-center justify-content-between">
      <OIDTooltip
        title={"Edit"}
        placement="top"
        arrow
        onClick={() => handleEdit(params.row.details)}
      >
        <svg
          className="mouse-pointer"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.243 16.0001H18V18.0001H0V13.7571L9.9 3.85708L14.142 8.10108L6.242 16.0001H6.243ZM11.313 2.44408L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L15.556 6.68608L11.314 2.44408H11.313Z"
            fill="#0B61DD"
          />
        </svg>
      </OIDTooltip>

      <OIDTooltip
        title={"Delete"}
        placement="bottom"
        arrow
        onClick={() => handleRowDelete(params.row.details)}
      >
        <svg
          className="mx-4 mouse-pointer"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5V0H15V2ZM7 7V15H9V7H7ZM11 7V15H13V7H11Z"
            fill="#F71C1C"
          />
        </svg>
      </OIDTooltip>

      <Link
        to={`/api-key-generated/${params.row.id}`}
        className="mouse-pointer view-details"
      >
        View Details
      </Link>

      {apiData && (
        <DeleteApiKeyDialog
          title={`Delete Api Key: ${apiData?.name ?? ""}`}
          data={apiData}
          dialogProps={{
            open: openDeleteApiKeyDialog,
            maxWidth: "xs",
            onClose: () => setOpenDeleteApiKeyDialog(false),
          }}
        />
      )}

      <GenerateApiKeyDialog
        title={`Edit Api Key: ${apiData?.name ?? ""}`}
        data={apiData}
        dialogProps={{
          open: openGenerateApiKeyDialog,
          maxWidth: "sm",
          onClose: () => {
            setOpenGenerateApiKeyDialog(false);
            setIsEditMode(false);
          },
        }}
      />
    </div>
  );
};
