import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetApiKeyDashboardSummaryStatsQueryVariables = Types.Exact<{
  environment: Types.ConsoleEnv;
}>;


export type GetApiKeyDashboardSummaryStatsQuery = { __typename?: 'Query', getApiKeyDashboardSummaryStats?: { __typename?: 'ApiKeyDashboardSummaryStatsOutPut', apiKeysTotalCountForUser?: number | null, apiKeysFailedCountForUser?: number | null, apiKeysSuccessCountForUser?: number | null } | null };


export const GetApiKeyDashboardSummaryStatsDocument = gql`
    query GetApiKeyDashboardSummaryStats($environment: CONSOLE_ENV!) {
  getApiKeyDashboardSummaryStats(environment: $environment) {
    apiKeysTotalCountForUser
    apiKeysFailedCountForUser
    apiKeysSuccessCountForUser
  }
}
    `;

/**
 * __useGetApiKeyDashboardSummaryStatsQuery__
 *
 * To run a query within a React component, call `useGetApiKeyDashboardSummaryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeyDashboardSummaryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeyDashboardSummaryStatsQuery({
 *   variables: {
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetApiKeyDashboardSummaryStatsQuery(baseOptions: Apollo.QueryHookOptions<GetApiKeyDashboardSummaryStatsQuery, GetApiKeyDashboardSummaryStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiKeyDashboardSummaryStatsQuery, GetApiKeyDashboardSummaryStatsQueryVariables>(GetApiKeyDashboardSummaryStatsDocument, options);
      }
export function useGetApiKeyDashboardSummaryStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiKeyDashboardSummaryStatsQuery, GetApiKeyDashboardSummaryStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiKeyDashboardSummaryStatsQuery, GetApiKeyDashboardSummaryStatsQueryVariables>(GetApiKeyDashboardSummaryStatsDocument, options);
        }
export type GetApiKeyDashboardSummaryStatsQueryHookResult = ReturnType<typeof useGetApiKeyDashboardSummaryStatsQuery>;
export type GetApiKeyDashboardSummaryStatsLazyQueryHookResult = ReturnType<typeof useGetApiKeyDashboardSummaryStatsLazyQuery>;
export type GetApiKeyDashboardSummaryStatsQueryResult = Apollo.QueryResult<GetApiKeyDashboardSummaryStatsQuery, GetApiKeyDashboardSummaryStatsQueryVariables>;