import { Dialog, Box } from "@mui/material";
import * as React from "react";
import { MdClose } from "react-icons/md";

interface Props {
  pdfData: string;
  openPDFPreviewer: boolean;
}

export const PreviewPDF: React.FC<Props> = ({
  pdfData,
  openPDFPreviewer = true,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(openPDFPreviewer);
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const downloadPDF = (base64: string) => {
    if (!openPDFPreviewer) return;
    openPDFPreviewer = false;
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/pdf;base64,${base64}`;
    downloadLink.download = "invoice.pdf";
    downloadLink.click();
  };

  return (
    <>
      {windowSize.width >= 991.9 && (
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(!openPDFPreviewer)}
          fullWidth={true}
          maxWidth={"md"}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            {/* <Box
              onClick={() => setIsOpen(!openPDFPreviewer)}
              sx={{
                position: "absolute",
                top: "0.5rem",
                right: "0.5rem",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                cursor: "pointer",
                border: "2px solid #afafaf",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#afafaf",
                fontSize: "1.5rem",
              }}
            >
              <MdClose />
            </Box> */}
            <iframe
              src={`data:application/pdf;base64,${pdfData}`}
              style={{ width: "100%", height: "100vh" }}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
};
