import { Button, styled } from "@mui/material"

export const ButtonBox = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  height: "auto",
  fontSize: "0.85rem",
  padding: "0.5rem 2rem",
  transition: "0.3s all ease-in-out",
  borderRadius: "5px",
}))
