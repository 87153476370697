import React from "react"
import { ModalFrameBox } from "./modal-frame.style"

interface Props {
  title: string
  subtitle: string
  children: React.ReactNode
  actionButtonType: "button" | "submit" | "reset" | undefined
  actionButtonText: string
  buttonDisabled?: boolean

  actionButtonFunc: () => void
  handleBackButton: () => void
}
export const ModalFrame: React.FC<Props> = ({
  title,
  subtitle,
  children,
  buttonDisabled,
  handleBackButton,
  actionButtonFunc,
  actionButtonText,
  actionButtonType,
}) => {
  return (
    <ModalFrameBox>
      <div className="titleContainer">
        <h3 className="mb-1">{title}</h3>
        <p className="title mb-0">{subtitle}</p>
      </div>

      {children}

      <div className="d-flex align-items-center mt-4">
        <button
          type="button"
          className="btn od_btn_outline me-4"
          onClick={handleBackButton}
        >
          Back
        </button>

        <button
          type={actionButtonType}
          className="btn od_btn_flat"
          disabled={buttonDisabled}
          onClick={actionButtonFunc}
        >
          {actionButtonText}
        </button>
      </div>
    </ModalFrameBox>
  )
}
