import { getErrorMessage } from "common"
import { Progress, TOASTER_TYPE, ToastMessage } from "components"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { FiX } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useCurrentUser } from "../../helpers/lib/useLib.hooks"
import { handleGetImageFile, validURL } from "../../helpers/utils.helper"
import { CreateBusinessBox } from "./create-business.style"
import { ModalFrame } from "./ModalFrame/ModalFrame"
import {
  useCreateDeveloperBusinessProfileMutation,
  useUploadBusinessLogoMutation,
} from "./__types/CreateBusiness.types"
import { useVerifyBusinessRcNumberMutation } from "./__types/VerifyRc.types"
import CircularProgress from "@mui/material/CircularProgress"

const validationSchema = Yup.object().shape({
  businessName: Yup.string().required("Business name is required"),
  businessEmail: Yup.string()
    .required("Business email is required")
    .email("Invalid email. Please check your email"),
  businessWebsite: Yup.string()
    .required("Business website is required")
    .test(
      "Business-website",
      "Invalid website. Please check your website link",
      value => validURL(value!)
    ),
  about: Yup.string().required("About business is required"),
  RcNo: Yup.string().required("RC number is required"),
})

export const CreateBusinessProfile = () => {
  const navigate = useNavigate()
  const { loading, user } = useCurrentUser()
  const [onCreateBusiness, { loading: cb_loading }] =
    useCreateDeveloperBusinessProfileMutation()
  const [onUploadLogo, { loading: lg_loading }] =
    useUploadBusinessLogoMutation()
  const [
    onVerifyRcNo,
    { loading: vr_loading, error: vr_error, data: vr_data },
  ] = useVerifyBusinessRcNumberMutation()
  const [previewImage, setPreviewImage] = useState<string | null>()
  const [showUpload, setShowUpload] = useState<Boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [isRcVerified, setIsRcVerified] = useState<boolean>(false)

  useEffect(() => {
    if (user && user.developerConsoleProfile === null) {
      setShow(true)
    }
  }, [user])

  const initialValues = {
    _id: "",
    businessName: "",
    businessWebsite: "",
    businessEmail: "",
    RcNo: "",
    about: "",
    businessLogo: "",
  }

  const handleVerifyRc = async (rc: string, businessName: string) => {
    if (rc.length === 7) {
      const data = {
        businessName: businessName,
        oneId: user?.user?.oneId!,
        RcNo: rc,
      }

      await onVerifyRcNo({
        variables: data,
      }).then(res => {
        setIsRcVerified(true)
      })
    }
  }

  const {
    values: { businessName, businessEmail, businessWebsite, about, RcNo },
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      if (!user?.user?.oneId) {
        ToastMessage(TOASTER_TYPE.ERROR, "Missing OneID Code!")
        return
      }

      const data = {
        ...values,
        oneId: user.user.oneId,
        _id: vr_data?.verifyBusinessRCNumber?._id!,
      };

      try {
        await onCreateBusiness({
          variables: data,
        }).then(() => {
          setShowUpload(true)
        })
      } catch (e) {
        ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(e))
      }
    },
  })

  const handleImageUpload = async () => {
    try {
      //Logo upload section
      await onUploadLogo({
        variables: {
          businessLogo: previewImage,
        },
      }).then(() => {
        setPreviewImage(null)
        setShowUpload(false)
        setShow(false)
        navigate("/")
      })
    } catch (err) {}
  }

  const goBack = () => {
    localStorage.removeItem("1D_AU")
    localStorage.removeItem("1D_AUR")
    window.history.back()
  }

  const chooseImageToUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleGetImageFile(e).then(value => {
      setPreviewImage(value)
    })
  }

  const removeImage = () => setPreviewImage(null)

  return (
    <Modal show={show}>
      {(loading || cb_loading || lg_loading) && <Progress />}

      <CreateBusinessBox className="p-4">
        {!showUpload && (
          <>
            <ModalFrame
              title="Please Fill in Business Information"
              subtitle={`Hi ${user?.firstName}, please fill in business information to proceed`}
              actionButtonText="Continue"
              actionButtonType="submit"
              buttonDisabled={!isRcVerified || cb_loading}
              actionButtonFunc={handleSubmit}
              handleBackButton={goBack}
            >
              <Form className="my-4">
                <Form.Group className="mb-3">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Enter Business name"
                    onBlur={handleBlur}
                    value={businessName}
                    name={"businessName"}
                    onChange={handleChange}
                    className={
                      touched.businessName && errors.businessName
                        ? "error"
                        : undefined
                    }
                  />
                  {touched.businessName && errors.businessName ? (
                    <div className="form-error-message">
                      {errors.businessName}
                    </div>
                  ) : null}
                </Form.Group>

                {businessName ? (
                  <Form.Group className="mb-3">
                    <Form.Label>RC Number</Form.Label>
                    <Form.Control
                      type="text"
                      size="lg"
                      placeholder="Enter RC number"
                      onBlur={handleBlur}
                      value={RcNo}
                      name={"RcNo"}
                      onChange={e => {
                        handleChange(e)
                        handleVerifyRc(e.target.value, businessName)
                      }}
                      className={
                        touched.RcNo && errors.RcNo ? "error" : undefined
                      }
                    />
                    {touched.RcNo && errors.RcNo ? (
                      <div className="form-error-message">{errors.RcNo}</div>
                    ) : null}

                    {/* Alerrt section */}
                    {vr_loading ? (
                      <div className="alert outlineAlert">
                        <CircularProgress />
                        <p className="mb-0">
                          Please wait... Your RC Number is being verified.
                        </p>
                      </div>
                    ) : null}

                    {!vr_error && !vr_data && !vr_loading ? (
                      <div className="alert infoAleert">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 33.6673C7.79501 33.6673 0.333344 26.2057 0.333344 17.0007C0.333344 7.79565 7.79501 0.333984 17 0.333984C26.205 0.333984 33.6667 7.79565 33.6667 17.0007C33.6667 26.2057 26.205 33.6673 17 33.6673ZM15.3333 15.334V25.334H18.6667V15.334H15.3333ZM15.3333 8.66732V12.0007H18.6667V8.66732H15.3333Z"
                            fill="#0B61DD"
                          />
                        </svg>

                        <p className="mb-0">
                          Don’t have a RC Number? <a>Contact Support</a>
                        </p>
                      </div>
                    ) : null}

                    {vr_error ? (
                      <div className="alert dangerAlert">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.9999 33.6673C7.79492 33.6673 0.333252 26.2057 0.333252 17.0007C0.333252 7.79565 7.79492 0.333984 16.9999 0.333984C26.2049 0.333984 33.6666 7.79565 33.6666 17.0007C33.6666 26.2057 26.2049 33.6673 16.9999 33.6673ZM16.9999 14.644L12.2866 9.92898L9.92825 12.2873L14.6433 17.0007L9.92825 21.714L12.2866 24.0723L16.9999 19.3573L21.7133 24.0723L24.0716 21.714L19.3566 17.0007L24.0716 12.2873L21.7133 9.92898L16.9999 14.644Z"
                            fill="#C50707"
                          />
                        </svg>

                        <p className="mb-0">
                          Verification failed. No match found. Pleasee check
                          that you entered your RC Number correctly.
                        </p>
                      </div>
                    ) : null}

                    {vr_data ? (
                      <div className="alert successAlert">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.0002 33.6673C7.79516 33.6673 0.333496 26.2057 0.333496 17.0007C0.333496 7.79565 7.79516 0.333984 17.0002 0.333984C26.2052 0.333984 33.6668 7.79565 33.6668 17.0007C33.6668 26.2057 26.2052 33.6673 17.0002 33.6673ZM15.3385 23.6673L27.1218 11.8823L24.7652 9.52565L15.3385 18.954L10.6235 14.239L8.26683 16.5956L15.3385 23.6673Z"
                            fill="#149E4C"
                          />
                        </svg>

                        <p className="mb-0">Verification successful!</p>
                      </div>
                    ) : null}
                  </Form.Group>
                ) : null}

                <Form.Group className="mb-3">
                  <Form.Label>Business Email</Form.Label>
                  <Form.Control
                    type="text"
                    size="lg"
                    placeholder="Enter business email"
                    onBlur={handleBlur}
                    value={businessEmail}
                    name={"businessEmail"}
                    onChange={handleChange}
                    className={
                      touched.businessEmail && errors.businessEmail
                        ? "error"
                        : undefined
                    }
                  />
                  {touched.businessEmail && errors.businessEmail ? (
                    <div className="form-error-message">
                      {errors.businessEmail}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Business Website</Form.Label>
                  <Form.Control
                    type="text"
                    size="lg"
                    placeholder="Enter business website"
                    onBlur={handleBlur}
                    value={businessWebsite}
                    name={"businessWebsite"}
                    onChange={handleChange}
                    className={
                      touched.businessWebsite && errors.businessWebsite
                        ? "error"
                        : undefined
                    }
                  />
                  {touched.businessWebsite && errors.businessWebsite ? (
                    <div className="form-error-message">
                      {errors.businessWebsite}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>About Business</Form.Label>
                  <Form.Control
                    as="textarea"
                    size="lg"
                    placeholder="About business"
                    onBlur={handleBlur}
                    value={about}
                    name={"about"}
                    onChange={handleChange}
                    className={
                      touched.about && errors.about ? "error" : undefined
                    }
                  />

                  {touched.about && errors.about ? (
                    <div className="form-error-message">{errors.about}</div>
                  ) : null}
                </Form.Group>
              </Form>
            </ModalFrame>
          </>
        )}

        {showUpload && (
          <>
            <ModalFrame
              title="A little more about your business"
              subtitle=" Upload your business logo"
              actionButtonText="Create Account"
              actionButtonType="button"
              buttonDisabled={lg_loading}
              actionButtonFunc={handleImageUpload}
              handleBackButton={() => setShowUpload(false)}
            >
              <div className="uploadBox mb-1">
                {previewImage ? (
                  <div className="previewImage">
                    <img src={previewImage} alt="" />
                    <FiX className="clearImage" onClick={removeImage} />
                    <p className="mt-2 mb-0">Change logo</p>
                  </div>
                ) : (
                  <div className="">
                    <h5 className="">UPLOAD COMPANY LOGO</h5>
                    <div className="filePicker">
                      <input
                        type="file"
                        accept=".jpeg, .png, .jpg, .svg"
                        onChange={e => chooseImageToUpload(e)}
                      />
                      <p className="">Click to upload file</p>
                    </div>
                  </div>
                )}
              </div>
              <small className="imgNote">
                Only png, jpeg and jpg formats are allowed with a max file size
                of 1MB
              </small>
            </ModalFrame>
          </>
        )}
      </CreateBusinessBox>
    </Modal>
  )
}
