import React from "react";
import { Filter, ConsoleEnv, GraphFilter } from "types";

export interface IApiKeyContext {
  isEditMode: boolean;
  creationCompleted: boolean;
  apiKeyId: string | undefined;
  filters: Filter | undefined;
  envMode: ConsoleEnv | undefined;

  setFilters(filter: Filter): void;
  setApiKeyId(apiKeyId: string): void;
  setIsEditMode(isEditMode: boolean): void;
  setCreationCompleted(creationCompleted: boolean): void;
  setEnvMode(envMode: ConsoleEnv): void;
}

export const ApiKeyContext = React.createContext<IApiKeyContext>({
  apiKeyId: "",
  filters: undefined,
  isEditMode: false,
  creationCompleted: false,
  envMode: ConsoleEnv.SANDBOX,

  setFilters() {},
  setApiKeyId() {},
  setIsEditMode() {},
  setCreationCompleted() {},
  setEnvMode() {},
});
