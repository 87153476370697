import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  DrawerProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppMenuList,
  AppMenuListProps,
  SubMenuListProps,
} from "../../../helpers/appMenuList.helper";
import { logoutFromApp } from "../../../helpers/utils.helper";
import { TextLogo } from "../../lib/Logo/Logo";
import { DrawerBox } from "./navigator.styles";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FiChevronsRight, FiChevronsLeft } from "react-icons/fi";

interface Props {
  drawerProps: DrawerProps;
  className?: React.CSSProperties;
  handleDrawerToggle?: any;
}

export const Navigator: React.FC<Props> = ({
  className,
  drawerProps,
  handleDrawerToggle,
}) => {
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (value: string | null) => location.pathname === value;

  const routeToPath = (path: string | null) => {
    if (path) {
      navigate(path);
    } else {
      logoutFromApp();
    }
  };

  const handleClick = () => setOpenMenu(!openMenu);

  const linkMenuItem = (
    index: number,
    item?: AppMenuListProps,
    subItem?: SubMenuListProps
  ) => {
    let link: string | null = "";
    let title: string = "";
    let icon: string | undefined = "";
    let isComingSoon!: boolean | undefined;

    if (item) {
      link = item.link;
      title = item.title;
      icon = item.icon;
      isComingSoon = item.isComingSoon;
    }
    if (subItem) {
      link = subItem.link;
      title = subItem.title;
      isComingSoon = subItem.isComingSoon;
    }

    return (
      <ListItem
        button
        key={`${index}-${title}`}
        onClick={() =>
          isComingSoon
            ? routeToPath("/coming-soon")
            : routeToPath(link)
        }
        className={classNames(
          "menuList",
          isActive(link) ? "itemActiveItem" : null,
          title === "Logout" ? "logOutItem" : ""
        )}
      >
        {item?.icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText className="text-truncate" primary={title} />
        {isComingSoon && <div className="comingSoonTag">Coming Soon</div>}
      </ListItem>
    );
  };

  return (
    <DrawerBox {...drawerProps}>
      <List style={className}>
        <div className="logoPadding">
          <TextLogo />

          {/* <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon />
          </IconButton> */}
        </div>

        <div className="listHolder">
          {AppMenuList.map((menu, index) => {
            if (!menu.hasChildren) {
              return (
                <div
                  // className={classNames(
                  //   "menuList",
                  //   isActive(menu.link) ? "itemActiveItem" : null
                  // )}
                  key={`${index}-${menu.link}`}
                >
                  {linkMenuItem(index, menu)}
                </div>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <ListItem
                    button
                    key={`${menu.title}-${index}`}
                    onClick={handleClick}
                    className={"listWithCHildren"}
                  >
                    <ListItemIcon>{menu.icon}</ListItemIcon>
                    <ListItemText primary={menu.title} />
                    {openMenu ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    key={index}
                    in={openMenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="li"
                      disablePadding
                      key={menu.title}
                      className={"listChildren"}
                    >
                      {menu.children?.map((subMenu, index) => {
                        return linkMenuItem(index, subMenu);
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }
          })}
        </div>
      </List>
    </DrawerBox>
  );
};
