import { styled } from "@mui/material";

export const NavBox = styled("nav")(({ theme }) => ({
  padding: theme.spacing(2, 2),
  backgroundColor: "#F5FAFF",
  position: "sticky",
  right: "0",
  left: "0",
  top: "0",
  zIndex: "1000",

  "@media (max-width:767.9px)": {
    position: "fixed",
    left: "0",
    right: "0",
  },

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.drawerWidth,
  },

  [theme.breakpoints.down("xs")]: {
    padding: "2rem 1rem",
  },

  "& .headerSection": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,

    "& .pageTitle": {
      fontSize: "2rem",
      fontWeight: 600,
      color: "#272727",
      marginBottom: 0,

      "@media (max-width:767.9px)": {
        fontSize: "1.25rem",
        fontWeight: 700,
      },
    },

    "& .modeContainer": {
      border: "2px solid #E4E4E4",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "0.35rem 0.7rem",
      transition: "0.3s all ease",
      margin: "0 1.5rem 0 3rem",

      "& label": {
        marginRight: "0 !important",
      },

      "& .MuiTypography-root": {
        fontWeight: 600,
        fontSize: "0.875rem",
        color: " #939393",
      },

      "@media (max-width:991.9px)": {
        padding: "0.1rem 0.25rem",

        "& label": {
          margin: "0 !important",
        },
      },
    },

    "& .liveMode": {
      borderColor: "#0B61DD !important",

      "& .MuiTypography-root": {
        color: " #0B61DD",
      },
    },

    "& .actionSection": {
      display: "flex",
      alignItems: "center",

      "& .costContainer": {
        fontSize: "0.875rem",

        "& .title": {
          color: "#939393",
        },
        "& .amount": {
          fontWeight: 600,
          color: " #272727",
        },
      },

      "& .iconContainer": {
        display: "flex",
        gap: "1rem",
        margin: "0 1rem 0 0",

        "& img": {
          cursor: "pointer",
        },
      },

      "& .avatarContainer": {
        "& .initials": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "#00499B",
          color: "#ffffff",
          fontWeight: 600,
          fontSize: "1.05rem",
          cursor: "pointer",
        },

        "& .MuiList-root": {
          minWidth: "130px",
        },

        "& .dropCard": {
          minWidth: "130px",
          fontSize: "1rem",

          "& .list-group-item": {
            padding: ".5rem 1rem",
            cursor: "pointer",
            borderWidth: "0 0 0 0",

            "&:last-child": {
              borderWidth: "1px 0 0 0",
            },
          },
        },
      },
    },
  },

  "& .toggler": {
    display: "flex",
    height: "25px",
    width: "25px",
    background: "#cccccc",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "-25px",
    top: "72px",
  },
}));
