import { renderSVG } from "./utils.helper";

export interface SubMenuListProps {
  link: string;
  title: string;
  icon?: any;
  isComingSoon?: boolean;
}
export interface AppMenuListProps {
  title: string;
  link: string | null;
  icon?: any;
  hasChildren?: boolean;
  children?: SubMenuListProps[];
  isComingSoon?: boolean;
}
export const AppMenuList: AppMenuListProps[] = [
  {
    title: "Dashboard",
    icon: renderSVG(
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3157_1215)">
          <path
            d="M2.5 10.8333H9.16667V2.5H2.5V10.8333ZM2.5 17.5H9.16667V12.5H2.5V17.5ZM10.8333 17.5H17.5V9.16667H10.8333V17.5ZM10.8333 2.5V7.5H17.5V2.5H10.8333Z"
            fill="#272727"
          />
        </g>
        <defs>
          <clipPath id="clip0_3157_1215">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    link: "/",
    hasChildren: false,
    isComingSoon: false,
  },
  {
    title: "API-Keys Generated",
    icon: renderSVG(
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.16667 0.5V13.8333H15.5V15.5H0.5V0.5H2.16667ZM14.6167 2.95L16.3833 4.71667L11.3333 9.76833L8.83333 7.26833L5.55 10.5517L3.78333 8.78333L8.83333 3.73333L11.3333 6.23333L14.6167 2.95Z"
          fill="#272727"
        />
      </svg>
    ),
    link: "/api",
    hasChildren: true,
    isComingSoon: false,
    children: [
      {
        title: "API-Keys Generated",
        link: "/api-key-generated",
        icon: renderSVG(
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 8.99984H3.83333V16.4998H0.5V8.99984ZM12.1667 5.6665H15.5V16.4998H12.1667V5.6665ZM6.33333 0.666504H9.66667V16.4998H6.33333V0.666504Z"
              fill="#0B61DD"
            />
          </svg>
        ),
        isComingSoon: false,
      },
      {
        title: "API Logs",
        link: "/api-key-logs",
        icon: renderSVG(
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.99984 0.666504C13.6023 0.666504 17.3332 4.39734 17.3332 8.99984C17.3332 13.6023 13.6023 17.3332 8.99984 17.3332C4.39734 17.3332 0.666504 13.6023 0.666504 8.99984H2.33317C2.33317 12.6815 5.31817 15.6665 8.99984 15.6665C12.6815 15.6665 15.6665 12.6815 15.6665 8.99984C15.6665 5.31817 12.6815 2.33317 8.99984 2.33317C6.9465 2.33317 5.10984 3.2615 3.88734 4.72067L5.6665 6.49984H0.666504V1.49984L2.70567 3.53817C4.23317 1.77984 6.4865 0.666504 8.99984 0.666504ZM9.83317 4.83317V8.654L12.5357 11.3565L11.3565 12.5357L8.1665 9.344V4.83317H9.83317Z"
              fill="black"
            />
          </svg>
        ),
        isComingSoon: false,
      },
    ],
  },
  {
    title: "Perform Lookup",
    icon: renderSVG(
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0257 12.8472L17.5946 16.4161L16.4161 17.5946L12.8472 14.0257C11.5639 15.0523 9.9365 15.6665 8.1665 15.6665C4.0265 15.6665 0.666504 12.3065 0.666504 8.1665C0.666504 4.0265 4.0265 0.666504 8.1665 0.666504C12.3065 0.666504 15.6665 4.0265 15.6665 8.1665C15.6665 9.9365 15.0523 11.5639 14.0257 12.8472ZM12.8823 6.49984C12.1963 4.55739 10.3445 3.1665 8.1665 3.1665C5.404 3.1665 3.1665 5.404 3.1665 8.1665H4.83317C4.83317 6.3265 6.3265 4.83317 8.1665 4.83317C8.7425 4.83317 9.28459 4.97953 9.7575 5.23706L8.99984 6.49984H12.8823ZM13.1665 8.1665H11.4998C11.4998 10.0065 10.0065 11.4998 8.1665 11.4998C7.5905 11.4998 7.0484 11.3535 6.5755 11.0959L7.33317 9.83317H5.27956H3.45075C4.13678 11.7756 5.98846 13.1665 8.1665 13.1665C10.929 13.1665 13.1665 10.929 13.1665 8.1665Z"
          fill="#0B61DD"
        />
      </svg>
    ),
    link: "/perform-lookup",
    hasChildren: false,
    isComingSoon: false,
  },
  {
    title: "Billing",
    icon: renderSVG(
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.3334 7.16667V14.6667C17.3334 14.8877 17.2456 15.0996 17.0893 15.2559C16.9331 15.4122 16.7211 15.5 16.5001 15.5H1.50008C1.27907 15.5 1.06711 15.4122 0.910826 15.2559C0.754545 15.0996 0.666748 14.8877 0.666748 14.6667V7.16667H17.3334ZM17.3334 3.83333H0.666748V1.33333C0.666748 1.11232 0.754545 0.900358 0.910826 0.744078C1.06711 0.587797 1.27907 0.5 1.50008 0.5H16.5001C16.7211 0.5 16.9331 0.587797 17.0893 0.744078C17.2456 0.900358 17.3334 1.11232 17.3334 1.33333V3.83333Z"
          fill="#272727"
        />
      </svg>
    ),
    link: "/billing",
    hasChildren: false,
    isComingSoon: false,
  },
  {
    title: "Identity Authentication",
    icon: renderSVG(
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.1525 2.35516L8 0.833496L14.8475 2.35516C15.0326 2.3963 15.198 2.4993 15.3167 2.64717C15.4353 2.79503 15.5 2.97893 15.5 3.1685V11.491C15.4999 12.3141 15.2967 13.1245 14.9082 13.8502C14.5198 14.5759 13.9582 15.1944 13.2733 15.651L8 19.1668L2.72667 15.651C2.04189 15.1945 1.48038 14.5761 1.09196 13.8505C0.70353 13.125 0.500197 12.3148 0.5 11.4918V3.1685C0.500033 2.97893 0.564698 2.79503 0.683325 2.64717C0.801951 2.4993 0.967447 2.3963 1.1525 2.35516ZM8 9.16683C8.55253 9.16683 9.08244 8.94734 9.47314 8.55664C9.86384 8.16593 10.0833 7.63603 10.0833 7.0835C10.0833 6.53096 9.86384 6.00106 9.47314 5.61036C9.08244 5.21966 8.55253 5.00016 8 5.00016C7.44747 5.00016 6.91756 5.21966 6.52686 5.61036C6.13616 6.00106 5.91667 6.53096 5.91667 7.0835C5.91667 7.63603 6.13616 8.16593 6.52686 8.55664C6.91756 8.94734 7.44747 9.16683 8 9.16683ZM4.2725 13.3335H11.7275C11.6265 12.4155 11.1903 11.5671 10.5024 10.9508C9.8146 10.3345 8.92354 9.99367 8 9.99367C7.07646 9.99367 6.1854 10.3345 5.49757 10.9508C4.80974 11.5671 4.37352 12.4155 4.2725 13.3335Z"
          fill="#272727"
        />
      </svg>
    ),
    link: "/identity-authentication",
    hasChildren: false,
    isComingSoon: true,
  },

  {
    title: "Libraries & Plugins",
    icon: renderSVG(
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3333 0.666504L15.5 4.83317V16.5065C15.4998 16.7258 15.4125 16.9361 15.2573 17.0911C15.1022 17.2461 14.8918 17.3332 14.6725 17.3332H1.3275C1.10865 17.3316 0.899181 17.2441 0.744348 17.0894C0.589515 16.9347 0.501746 16.7254 0.5 16.5065V1.49317C0.5 1.0365 0.870833 0.666504 1.3275 0.666504H11.3333ZM12.7142 8.99984L9.76667 6.05317L8.58917 7.23317L10.3567 8.99984L8.59 10.7673L9.7675 11.9465L12.7142 8.99984ZM3.28583 8.99984L6.2325 11.9465L7.41083 10.7673L5.64333 8.99984L7.41 7.23234L6.23333 6.05317L3.28583 8.99984Z"
          fill="#272727"
        />
      </svg>
    ),
    link: "/libraries-plugins",
    hasChildren: false,
    isComingSoon: true,
  },

  {
    title: "Guides",
    icon: renderSVG(
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.99996 17.3332C4.39746 17.3332 0.666626 13.6023 0.666626 8.99984C0.666626 4.39734 4.39746 0.666504 8.99996 0.666504C13.6025 0.666504 17.3333 4.39734 17.3333 8.99984C17.3333 13.6023 13.6025 17.3332 8.99996 17.3332ZM12.75 5.24984L7.33329 7.33317L5.24996 12.7498L10.6666 10.6665L12.75 5.24984ZM8.99996 9.83317C8.77894 9.83317 8.56698 9.74537 8.4107 9.58909C8.25442 9.43281 8.16663 9.22085 8.16663 8.99984C8.16663 8.77882 8.25442 8.56686 8.4107 8.41058C8.56698 8.2543 8.77894 8.1665 8.99996 8.1665C9.22097 8.1665 9.43293 8.2543 9.58921 8.41058C9.74549 8.56686 9.83329 8.77882 9.83329 8.99984C9.83329 9.22085 9.74549 9.43281 9.58921 9.58909C9.43293 9.74537 9.22097 9.83317 8.99996 9.83317Z"
          fill="#272727"
        />
      </svg>
    ),
    link: "/guides",
    hasChildren: false,
    isComingSoon: true,
  },

  {
    title: "Use Cases",
    icon: renderSVG(
      <svg
        width="14"
        height="19"
        viewBox="0 0 14 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.61747 14C3.36997 12.9391 2.2533 12.0716 1.79497 11.5C1.01013 10.5194 0.518256 9.33704 0.37602 8.08912C0.233784 6.8412 0.446967 5.57849 0.991011 4.44643C1.53506 3.31438 2.38783 2.35904 3.4511 1.69047C4.51436 1.02189 5.74487 0.667279 7.00087 0.667481C8.25687 0.667682 9.48726 1.02269 10.5503 1.6916C11.6134 2.36052 12.4658 3.31613 13.0095 4.44836C13.5532 5.58059 13.766 6.84337 13.6233 8.09124C13.4807 9.33911 12.9885 10.5213 12.2033 11.5016C11.745 12.0725 10.63 12.94 10.3825 14H3.61663H3.61747ZM10.3333 15.6666V16.5C10.3333 16.942 10.1577 17.3659 9.84514 17.6785C9.53258 17.9911 9.10866 18.1666 8.66663 18.1666H5.3333C4.89127 18.1666 4.46735 17.9911 4.15479 17.6785C3.84223 17.3659 3.66663 16.942 3.66663 16.5V15.6666H10.3333ZM7.8333 7.33748V3.99998L4.0833 9.00415H6.16663V12.3375L9.91663 7.33748H7.8333Z"
          fill="#272727"
        />
      </svg>
    ),
    link: "/use-cases",
    hasChildren: false,
    isComingSoon: true,
  },

  {
    title: "Settings",
    icon: renderSVG(
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.445 1.78772C4.30061 1.02129 5.30557 0.440215 6.39667 0.0810547C6.70865 0.471908 7.10472 0.787412 7.55543 1.00411C8.00614 1.22081 8.4999 1.33313 9 1.33272C9.5001 1.33313 9.99386 1.22081 10.4446 1.00411C10.8953 0.787412 11.2914 0.471908 11.6033 0.0810547C12.6944 0.440215 13.6994 1.02129 14.555 1.78772C14.3729 2.25309 14.298 2.7535 14.3358 3.2518C14.3736 3.75009 14.5231 4.23347 14.7733 4.66605C15.023 5.09946 15.3672 5.47101 15.7802 5.75307C16.1933 6.03513 16.6646 6.22044 17.1592 6.29522C17.393 7.41931 17.393 8.57947 17.1592 9.70355C16.1925 9.85189 15.3008 10.4202 14.7733 11.3327C14.523 11.7654 14.3734 12.2489 14.3356 12.7474C14.2978 13.2458 14.3728 13.7464 14.555 14.2119C13.6993 14.978 12.6944 15.5588 11.6033 15.9177C11.2913 15.527 10.8952 15.2117 10.4445 14.9951C9.99375 14.7786 9.50003 14.6664 9 14.6669C8.4999 14.6665 8.00614 14.7788 7.55543 14.9955C7.10472 15.2122 6.70865 15.5277 6.39667 15.9186C5.30564 15.5596 4.30068 14.9789 3.445 14.2127C3.62721 13.7472 3.7022 13.2467 3.6644 12.7482C3.6266 12.2498 3.47699 11.7662 3.22667 11.3336C2.97691 10.9003 2.63271 10.5289 2.21965 10.247C1.8066 9.96509 1.33531 9.77991 0.840834 9.70522C0.606929 8.58087 0.606929 7.42041 0.840834 6.29605C1.33539 6.22127 1.80672 6.03596 2.21978 5.75391C2.63284 5.47185 2.977 5.1003 3.22667 4.66689C3.47686 4.2343 3.6264 3.75092 3.6642 3.25263C3.702 2.75434 3.62708 2.25393 3.445 1.78855V1.78772ZM10.25 10.1644C10.5366 10.0014 10.7882 9.78339 10.9902 9.52282C11.1922 9.26225 11.3406 8.9643 11.427 8.64612C11.5134 8.32794 11.536 7.99581 11.4935 7.66886C11.451 7.34191 11.3443 7.0266 11.1794 6.74107C11.0146 6.45554 10.7949 6.20544 10.5329 6.00517C10.271 5.8049 9.97211 5.65841 9.65337 5.57414C9.33462 5.48987 9.00235 5.46948 8.67569 5.51414C8.34903 5.55881 8.03443 5.66764 7.75 5.83439C7.18048 6.16826 6.76615 6.71385 6.59741 7.35209C6.42867 7.99033 6.51922 8.66941 6.84931 9.24112C7.17941 9.81284 7.72223 10.2308 8.35934 10.4038C8.99645 10.5767 9.67611 10.4907 10.25 10.1644Z"
          fill="#272727"
        />
      </svg>
    ),
    link: "/settings",
    hasChildren: false,
    isComingSoon: true,
  },

  // {
  //   title: "Setting Old",
  //   icon: SettingIcon,
  //   link: "",
  //   hasChildren: false,
  //   children: [
  //     {
  //       title: "Developer",
  //       link: "/dashboard/developer-profile",
  //     },
  //     {
  //       title: "Account",
  //       link: "/dashboard/business-profile",
  //     },
  //   ],
  // },
  {
    title: "Logout",
    icon: renderSVG(
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.16683 17.3332C0.945816 17.3332 0.733854 17.2454 0.577574 17.0891C0.421294 16.9328 0.333496 16.7208 0.333496 16.4998V1.49984C0.333496 1.27882 0.421294 1.06686 0.577574 0.910582C0.733854 0.754301 0.945816 0.666504 1.16683 0.666504H12.8335C13.0545 0.666504 13.2665 0.754301 13.4228 0.910582C13.579 1.06686 13.6668 1.27882 13.6668 1.49984V16.4998C13.6668 16.7208 13.579 16.9328 13.4228 17.0891C13.2665 17.2454 13.0545 17.3332 12.8335 17.3332H1.16683ZM9.50016 12.3332L13.6668 8.99984L9.50016 5.6665V8.1665H4.50016V9.83317H9.50016V12.3332Z"
          fill="#272727"
        />
      </svg>
    ),
    link: null,
    hasChildren: false,
    isComingSoon: false,
  },
];
