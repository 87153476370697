import {
  ButtonProps,
  CircularProgress,
  Fab,
  FabProps,
  Tooltip,
} from "@mui/material"
import React from "react"
import { RiCustomerService2Line as CustomerServiceIcon } from "react-icons/ri"
import { Ripple } from "../../styledComponents/animatedStyles"
import { ButtonBox } from "./OIDButtons.styles"

interface OIDButtonProps {
  text: string
  loading?: boolean
  buttonProps?: ButtonProps
}
export const OIDButton: React.FC<OIDButtonProps> = ({
  text,
  loading,
  buttonProps,
}) => {
  return (
    <ButtonBox {...buttonProps}>
      {loading ? <CircularProgress /> : text}
    </ButtonBox>
  )
}

// OID FloatButton component
interface FloatButtonProps {
  title: string
  buttonProps?: FabProps
}
export const FloatButton: React.FC<FloatButtonProps> = ({
  title,
  buttonProps,
}) => {
  return (
    <Ripple>
      <Tooltip title={title}>
        <Fab {...buttonProps}>
          <CustomerServiceIcon className="customer_service" />
        </Fab>
      </Tooltip>
    </Ripple>
  )
}
