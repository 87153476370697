import { DevProfileFrame, Progress } from "components"
import React from "react"
import { useCurrentUser } from "../../../../helpers/lib/useLib.hooks"

export const DeveloperProfile = () => {
  const { loading, user } = useCurrentUser()

  const picture = user && user.profileImage ? user.profileImage : ""

  return (
    <React.Fragment>
      {loading && <Progress />}

      <DevProfileFrame
        name={`${user?.firstName} ${user?.lastName}`}
        picture={picture}
        isEditButton={false}
        type="developer"
      >
        <div className="">
          <div className="profile_list">
            <h5 className="mb-0 me-4">Username:</h5>
            <p className="mb-0">{user?.user?.username}</p>
          </div>
          <div className="profile_list">
            <h5 className="mb-0 me-4">OneID:</h5>
            <p className="mb-0">{user?.user?.oneId}</p>
          </div>
          <div className="profile_list mb-0">
            <h5 className="mb-0 me-4">Email:</h5>
            <p className="mb-0">{user?.user?.email}</p>
          </div>
        </div>
      </DevProfileFrame>
    </React.Fragment>
  )
}
