import { styled } from "@mui/material"

export const ChartBox = styled("div")(({ theme }) => ({
  position: "relative",
  maxHeight: "100%",

  "& .donutCenterCount": {
    position: "absolute",
    width: "45px",
    top: "50%",
    right: "50%",
    fontSize: "2.5rem",
    fontWeight: 600,
    marginTop: "-30px",
    marginRight: "-22.5px",
    textAlign: "center",
  },

  "& .emptyStateDonut": {
    width: "170px",
    height: "170px",
    display: "block",
    margin: "0 auto",
    position: "relative",

    "& .count": {
      position: "absolute",
      top: "50%",
      left: "50%",
      fontSize: "2.5rem",
      fontWeight: 600,
      margin: "-30px 0 0 -12.32px",
    },

    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
}))
