import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyBusinessRcNumberMutationVariables = Types.Exact<{
  businessName: Types.Scalars['String'];
  RcNo: Types.Scalars['String'];
  oneId: Types.Scalars['String'];
}>;


export type VerifyBusinessRcNumberMutation = { __typename?: 'Mutation', verifyBusinessRCNumber?: { __typename?: 'DeveloperConsoleProfile', _id?: string | null, businessName?: string | null, businessLogo?: string | null, RcNo?: string | null, oneId?: string | null, businessEmail?: string | null } | null };


export const VerifyBusinessRcNumberDocument = gql`
    mutation VerifyBusinessRCNumber($businessName: String!, $RcNo: String!, $oneId: String!) {
  verifyBusinessRCNumber(
    data: {businessName: $businessName, RcNo: $RcNo, oneId: $oneId}
  ) {
    _id
    businessName
    businessLogo
    RcNo
    oneId
    businessEmail
  }
}
    `;
export type VerifyBusinessRcNumberMutationFn = Apollo.MutationFunction<VerifyBusinessRcNumberMutation, VerifyBusinessRcNumberMutationVariables>;

/**
 * __useVerifyBusinessRcNumberMutation__
 *
 * To run a mutation, you first call `useVerifyBusinessRcNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyBusinessRcNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyBusinessRcNumberMutation, { data, loading, error }] = useVerifyBusinessRcNumberMutation({
 *   variables: {
 *      businessName: // value for 'businessName'
 *      RcNo: // value for 'RcNo'
 *      oneId: // value for 'oneId'
 *   },
 * });
 */
export function useVerifyBusinessRcNumberMutation(baseOptions?: Apollo.MutationHookOptions<VerifyBusinessRcNumberMutation, VerifyBusinessRcNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyBusinessRcNumberMutation, VerifyBusinessRcNumberMutationVariables>(VerifyBusinessRcNumberDocument, options);
      }
export type VerifyBusinessRcNumberMutationHookResult = ReturnType<typeof useVerifyBusinessRcNumberMutation>;
export type VerifyBusinessRcNumberMutationResult = Apollo.MutationResult<VerifyBusinessRcNumberMutation>;
export type VerifyBusinessRcNumberMutationOptions = Apollo.BaseMutationOptions<VerifyBusinessRcNumberMutation, VerifyBusinessRcNumberMutationVariables>;