import { styled } from "@mui/material";

export const BaseBox = styled("div")(({ theme }) => ({
  "& .root": {
    display: "block",
  },

  "& .drawer": {
    zIndex: 1,
    // [theme.breakpoints.up("sm")]: {
    //   flexShrink: 0,
    // },
  },

  "& .desktopSidebarContainer": {
    display: "block",

    "@media (max-width:599.9px)": {
      display: "none",
    },
  },

  "& .appContent": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#F5FAFF",
  },
  "& .mainContent": {
    flex: 1,
    padding: theme.spacing(1, 2),

    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.drawerWidth,
    },

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 2),
    },

    "@media (max-width:767.9px)": {
      marginTop: "78px",
    },
  },
}));
