import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeveloperConsoleProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DeveloperConsoleProfileQuery = { __typename?: 'Query', getDeveloperConsoleProfile?: { __typename?: 'DeveloperConsoleProfile', _id?: string | null, user?: string | null, businessName?: string | null, businessEmail?: string | null, businessWebsite?: string | null, businessLogo?: string | null, RcNo?: string | null, about?: string | null, oneId?: string | null } | null };


export const DeveloperConsoleProfileDocument = gql`
    query DeveloperConsoleProfile {
  getDeveloperConsoleProfile {
    _id
    user
    businessName
    businessEmail
    businessWebsite
    businessLogo
    RcNo
    about
    oneId
  }
}
    `;

/**
 * __useDeveloperConsoleProfileQuery__
 *
 * To run a query within a React component, call `useDeveloperConsoleProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeveloperConsoleProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeveloperConsoleProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeveloperConsoleProfileQuery(baseOptions?: Apollo.QueryHookOptions<DeveloperConsoleProfileQuery, DeveloperConsoleProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeveloperConsoleProfileQuery, DeveloperConsoleProfileQueryVariables>(DeveloperConsoleProfileDocument, options);
      }
export function useDeveloperConsoleProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeveloperConsoleProfileQuery, DeveloperConsoleProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeveloperConsoleProfileQuery, DeveloperConsoleProfileQueryVariables>(DeveloperConsoleProfileDocument, options);
        }
export type DeveloperConsoleProfileQueryHookResult = ReturnType<typeof useDeveloperConsoleProfileQuery>;
export type DeveloperConsoleProfileLazyQueryHookResult = ReturnType<typeof useDeveloperConsoleProfileLazyQuery>;
export type DeveloperConsoleProfileQueryResult = Apollo.QueryResult<DeveloperConsoleProfileQuery, DeveloperConsoleProfileQueryVariables>;