import { styled } from "@mui/material";

export const DeveloperDashboardBox = styled("div")(({ theme }) => ({
  "& .cardSection": {
    "& .dashCard": {
      width: "100%",
      height: "250px",
      background: "#FFFFFF",
      borderRadius: "8px",
      padding: "1.2rem",

      "& .title": {
        fontWeight: 600,
        fontSize: "1rem",
        color: "#272727",
        marginBottom: "1.2rem",
      },

      "& .body": {
        display: "grid",
        gridTemplateColumns: "60% 40%",
        gap: "0.5rem",
      },

      "& .failedTextHolder": {
        background: "#F5FAFF",
        borderRadius: "4px",
        padding: "0.8rem",

        "& h6": {
          fontSize: "0.75rem",
          color: "#001935",
          fontWeight: 600,
        },

        "& p": {
          fontSize: "0.75rem",
          color: "#272727",
        },
      },

      "& .textHolder": {
        display: "flex",

        "& .legendContainer": {
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "space-around",

          "& .desc": {
            fontSize: "0.75rem",
          },

          "& .metric": {
            display: "flex",
            alignItems: "center",
            gap: "1rem",

            "& .icon": {
              width: "25px",
              height: "25px",
              borderRadius: "4px",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            },

            "& .percent": {
              fontSize: "1.5rem",
              fontWeight: 600,
            },
          },

          "& .successCall": {
            "& .icon": {
              background: "#E8F7EE",
            },
          },

          "& .failedCall": {
            "& .icon": {
              background: "#FAE5E5",
            },
          },
        },
      },

      "& .lastBody": {
        gridTemplateColumns: "1fr",
        gap: "0.75rem",

        "& .amount": {
          fontWeight: 600,
          fontSize: "2.5rem",
          color: "#001935",
        },

        "& .desc": {
          fontSize: "0.875rem",
          color: "#272727",
          width: "80%",
        },
      },
    },
  },

  "& .profileSection": {
    "& h5": {
      fontWeight: 700,
      fontSize: "1.5rem",
    },
    "& span": {
      fontSize: "1rem",
    },
  },

  "& .note": {
    padding: "1.8rem",

    "@media (max-width:991px)": {
      padding: "1rem",
      fontSize: ".95rem",
    },

    "& svg": {
      minWidth: "20px",
      minHeight: "20x",
      marginRight: ".5rem",
    },

    "& p": {
      fontSize: "1rem",
      marginRight: "1.5rem",

      "@media (max-width:991px)": {
        marginRight: "0px",
        fontSize: "0.95rem",
      },
    },
  },

  "& .oneidCode": {
    display: "inline-block",
    fontWeight: 700,
    marginLeft: ".5rem",
  },

  "& .apiGenerationCard": {
    margin: "2.5rem 0",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
    width: "800px",
    maxWidth: "100%",

    "@media (max-width: 991px)": {
      width: "100%",
    },
  },

  "& .apiForm": {
    "& h5": {
      padding: "1rem 1.8rem",
      fontSize: "1.4rem",
      fontWeight: 700,

      "@media (max-width: 991px)": {
        padding: "1rem",
      },
    },

    "& .input-group": {
      height: "65px",
    },
    "& .form-control, .input-group-text": {
      borderRadius: "0px",
      paddingRight: "1.8rem",
      paddingLeft: "1.8rem",
      cursor: "pointer",

      "@media (max-width:991px)": {
        paddingRight: "1rem",
        paddingLeft: "1rem",
      },
    },
    "& .form-control": {
      borderWidth: "1px 1px 1px 0px",
      paddingLeft: "1.8rem",
      "&:focus": {
        boxShadow: "0 0 .125rem .25rem rgba(0,0,0,.075)!important",
        borderColor: "transparent",
      },
      "&[readonly]": {
        backgroundColor: "#fefefe",
      },
    },
    "& .input-group-text": {
      borderWidth: "1px 0px 1px 1px",
    },
  },

  "& .btnSection": {
    padding: "1rem 1.8rem 1.8rem",
    display: "flex",
    // gap: "2rem",
    "@media (max-width:991px)": {
      padding: "1rem",
    },
  },

  "& .chartLayout": {
    marginTop: theme.spacing(3),
  },

  "& .axisLabel": {
    margin: "1.5rem 0 1rem !important",
    fontSize: "0.875rem !important",
  },

  "& .apiCallContainer": {
    height: "500px",
    padding: "1rem  1rem 3rem 1rem !important",
    width: "100%",

    "@media (max-width:991px)": {
      height: "450px",
    },

    "& .viewTraffic": {
      fontSize: "1rem",
      fontWeight: 700,
    },
  },
}));
