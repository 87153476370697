import * as React from "react";
import { Typography, IconButton } from "@mui/material";

interface SubTitleProps {
  title: string;
}

export const AppSubTitle: React.FC<SubTitleProps> = ({ title }) => {
  const goBack = () => window.history.back();

  return (
    <div className="d-flex align-items-center pt-2 pb-3">
      <IconButton onClick={goBack} className="me-3 mouse-pointer">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.828 7.00017H16V9.00017H3.828L9.192 14.3642L7.778 15.7782L0 8.00017L7.778 0.222168L9.192 1.63617L3.828 7.00017Z"
            fill="#272727"
          />
        </svg>
      </IconButton>

      <Typography sx={{ fontWeight: 700, fontSize: "1.25rem" }}>
        {title}
      </Typography>
    </div>
  );
};
