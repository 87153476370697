import { gql } from "@apollo/client";

export const GET_DEVELOPER_PROFILE_DATA = gql`
  query getProfileData {
    userGetProfile {
      _id
      dob
      lga
      bvn
      nin
      phone
      userId
      gender
      country
      lastName
      firstName
      postalCode
      mothersName
      fathersName
      nationality
      kycVerified
      stateOfOrigin
      maritalStatus
      secondaryEmail
      primaryAddress
      parentsAddress
      secondaryAddress
      stateOfResidence
      verificationLevel
      nextOfKin {
        phone
        email
        address
        fullName
        relationship
      }
      socialMedia {
        twitter
        facebook
        linkedin
        instagram
      }

      kycVerified
      kycTypes
    }
  }
`;

export const DEVELOPER_BUSINESS_UPDATE_PROFILE = gql`
  mutation onUpdateDeveloperBusinessProfile(
    $businessName: String
    $businessWebsite: String
    $businessLogo: String
    $RcNo: String
    $about: String
    $oneId: String
  ) {
    updateDeveloperBusinessProfile(
      data: {
        businessName: $businessName
        businessWebsite: $businessWebsite
        businessLogo: $businessLogo
        RcNo: $RcNo
        about: $about
        oneId: $oneId
      }
    )
  }
`;

export const BUSINESS_UPDATE_LOGO = gql`
  mutation onUpdateLogo($logo: Upload!) {
    businessUpdateLogo(logo: $logo)
  }
`;

export const BUSINESS_RESEND_EMAIL_ACTIVATION = gql`
  mutation ($businessEmail: String!) {
    businessResendEmailActivation(businessEmail: $businessEmail)
  }
`;
