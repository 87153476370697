import {
  // FilterList,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  // Grid,
  // IconButton,
  // Typography,
  Menu,
  MenuItem,
  Slide,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { DateRangerPickerBox } from "./apiKeyFilter.style";
import { IScope, scopeLists } from "helpers";
import { Status } from "types";
import { ApiKeyContext } from "context";

interface Props {
  showFilterOptions?: boolean;

  //   handleShowFilterOptions: () => void;
}

export enum Frequency {
  WEEKYLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export const ApikKeyFilter: React.FC<Props> = ({
  showFilterOptions = true,
  //   handleShowFilterOptions,
}) => {
  const [statusAnchorEl, setStatusAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [scopeAnchorEl, setScopeAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [frequencyAnchorEl, setFrequencyAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);
  const [status, onSetStatus] = React.useState<Status>();
  const [scope, onSetScope] = React.useState<IScope>();
  const [frequency, onSetFrequency] = React.useState<string>();
  const openStatus = Boolean(statusAnchorEl);
  const openScope = Boolean(scopeAnchorEl);
  const openFrequency = Boolean(frequencyAnchorEl);
  const { setFilters } = React.useContext(ApiKeyContext);
  const containerRef = React.useRef(null);

  return (
    <Box
      sx={{
        pl: 1,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        overflow: "hidden",
      }}
      ref={containerRef}
    >
      {/* <Grid>
        <Typography
          variant="h6"
          fontWeight={600}
          sx={{ mr: 2, display: "flex", alignItems: "center" }}
        >
          Filter By:{" "}
          <IconButton onClick={handleShowFilterOptions} sx={{ ml: 1 }}>
            <FilterList />
          </IconButton>
        </Typography>
      </Grid> */}

      {showFilterOptions && (
        <Slide
          in={showFilterOptions}
          container={containerRef.current}
          direction="up"
        >
          <Box
            sx={{
              my: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              disableElevation
              variant="contained"
              aria-haspopup="true"
              id="filter-by-frequency"
              onClick={(e) => setFrequencyAnchorEl(e.currentTarget)}
              aria-expanded={openFrequency ? "true" : undefined}
              aria-controls={openFrequency ? "filter-by-frequency" : undefined}
              endIcon={
                openFrequency ? <KeyboardArrowUp /> : <KeyboardArrowDown />
              }
              sx={{
                background: ({ palette }) => palette.grey[200],
                textTransform: "capitalize",
                mr: 2,
                fontSize: "0.95rem",
                color: ({ palette }) => palette.grey[700],

                "&:hover": {
                  background: ({ palette }) => palette.grey[300],
                },
              }}
            >
              {frequency ?? "Frequency"}
            </Button>
            <Menu
              id="filter-by-frequency-menu"
              MenuListProps={{
                "aria-labelledby": "filter-by-frequency-button",
              }}
              anchorEl={frequencyAnchorEl}
              onClose={() => setFrequencyAnchorEl(null)}
              open={openFrequency}
            >
              <MenuItem
                onClick={() => {
                  onSetFrequency(Frequency.WEEKYLY);
                  setFrequencyAnchorEl(null);
                }}
                disableRipple
              >
                Weekly
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onSetFrequency(Frequency.MONTHLY);
                  setFrequencyAnchorEl(null);
                }}
                disableRipple
              >
                Monthly
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onSetFrequency(Frequency.YEARLY);
                  setFrequencyAnchorEl(null);
                }}
                disableRipple
              >
                Yearly
              </MenuItem>
            </Menu>

            <DateRangerPickerBox>
              <DatePicker
                label="Date From"
                inputFormat="dd MMM, yyyy"
                value={dateFrom}
                onChange={setDateFrom}
                renderInput={(params) => (
                  <TextField {...params} type="date" name="dateFrom" />
                )}
              />

              <Divider
                orientation="vertical"
                variant="middle"
                sx={{
                  height: "60% !important",
                  mr: 0.5,
                }}
                flexItem
              />

              <DatePicker
                label="Date To"
                inputFormat="dd MMM, yyyy"
                value={dateTo}
                onChange={setDateTo}
                renderInput={(params) => (
                  <TextField {...params} type="date" name="dateTo" />
                )}
              />
            </DateRangerPickerBox>

            <Button
              disableElevation
              variant="contained"
              aria-haspopup="true"
              id="filter-by-status"
              onClick={(e) => setStatusAnchorEl(e.currentTarget)}
              aria-expanded={openStatus ? "true" : undefined}
              aria-controls={openStatus ? "filter-by-status" : undefined}
              endIcon={openStatus ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              sx={{
                background: ({ palette }) => palette.grey[200],
                textTransform: "capitalize",
                mr: 2,
                fontSize: "0.95rem",
                color: ({ palette }) => palette.grey[700],

                "&:hover": {
                  background: ({ palette }) => palette.grey[300],
                },
              }}
            >
              {status ?? "Status"}
            </Button>
            <Menu
              id="filter-by-status-menu"
              MenuListProps={{
                "aria-labelledby": "filter-by-status-button",
              }}
              anchorEl={statusAnchorEl}
              onClose={() => setStatusAnchorEl(null)}
              open={openStatus}
            >
              <MenuItem
                onClick={() => {
                  onSetStatus(Status.ACTIVE);
                  setStatusAnchorEl(null);
                }}
                disableRipple
              >
                Active
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onSetStatus(Status.INACTIVE);
                  setStatusAnchorEl(null);
                }}
                disableRipple
              >
                Inactive
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onSetStatus(Status.EXPIRED);
                  setStatusAnchorEl(null);
                }}
                disableRipple
              >
                Expired
              </MenuItem>
            </Menu>

            <Button
              disableElevation
              variant="contained"
              aria-haspopup="true"
              id="filter-by-scope"
              onClick={(e) => setScopeAnchorEl(e.currentTarget)}
              aria-expanded={openScope ? "true" : undefined}
              aria-controls={openScope ? "filter-by-scope" : undefined}
              endIcon={openScope ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              sx={{
                background: ({ palette }) => palette.grey[200],
                textTransform: "capitalize",
                mr: 2,
                fontSize: "0.95rem",
                color: ({ palette }) => palette.grey[700],

                "&:hover": {
                  background: ({ palette }) => palette.grey[300],
                },
              }}
            >
              {scope?.value ?? "Scope"}
            </Button>
            <Menu
              id="filter-by-status-menu"
              MenuListProps={{
                "aria-labelledby": "filter-by-status-button",
              }}
              anchorEl={scopeAnchorEl}
              onClose={() => setScopeAnchorEl(null)}
              open={openScope}
            >
              {scopeLists.map((s) => (
                <MenuItem
                  key={s.id}
                  onClick={() => handleScopeChange(s)}
                  sx={{
                    "& svg": {
                      marginRight: ({ spacing }) => spacing(1.5),
                    },
                  }}
                >
                  {s.icon}
                  {s.name}
                </MenuItem>
              ))}
            </Menu>

            {/* <Button onClick={handleClearFilters}>Clear Filters</Button> */}
          </Box>
        </Slide>
      )}
    </Box>
  );

  function handleScopeChange(scope: IScope) {
    onSetScope(scope);
    setScopeAnchorEl(null);
  }

  function handleClearFilters() {
    setDateFrom(null);
    setDateTo(null);
    onSetScope(undefined);
    onSetStatus(undefined);
  }
};
