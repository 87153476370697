function isErrorWithMessage(error: unknown): error is Error {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  )
}

function toErrorWithMessage(likelyError: unknown): Error {
  if (isErrorWithMessage(likelyError)) return likelyError

  try {
    return new Error(JSON.stringify(likelyError))
  } catch (error) {
    return new Error(String(likelyError))
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message
}

export function getError(error: unknown) {
  return toErrorWithMessage(error)
}
