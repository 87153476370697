import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDeveloperBusinessProfileMutationVariables = Types.Exact<{
  _id: Types.Scalars['ID'];
  businessName: Types.Scalars['String'];
  businessEmail: Types.Scalars['String'];
  businessWebsite?: Types.InputMaybe<Types.Scalars['String']>;
  businessLogo?: Types.InputMaybe<Types.Scalars['String']>;
  RcNo: Types.Scalars['String'];
  about: Types.Scalars['String'];
  oneId: Types.Scalars['String'];
}>;


export type CreateDeveloperBusinessProfileMutation = { __typename?: 'Mutation', createDeveloperBusinessProfile?: { __typename?: 'DeveloperConsoleProfile', _id?: string | null, businessEmail?: string | null, businessName?: string | null } | null };

export type UploadBusinessLogoMutationVariables = Types.Exact<{
  businessLogo: Types.Scalars['Upload'];
}>;


export type UploadBusinessLogoMutation = { __typename?: 'Mutation', setBusinessProfileLogo: string };


export const CreateDeveloperBusinessProfileDocument = gql`
    mutation CreateDeveloperBusinessProfile($_id: ID!, $businessName: String!, $businessEmail: String!, $businessWebsite: String, $businessLogo: String, $RcNo: String!, $about: String!, $oneId: String!) {
  createDeveloperBusinessProfile(
    data: {_id: $_id, businessName: $businessName, businessEmail: $businessEmail, businessWebsite: $businessWebsite, businessLogo: $businessLogo, RcNo: $RcNo, about: $about, oneId: $oneId}
  ) {
    _id
    businessEmail
    businessName
  }
}
    `;
export type CreateDeveloperBusinessProfileMutationFn = Apollo.MutationFunction<CreateDeveloperBusinessProfileMutation, CreateDeveloperBusinessProfileMutationVariables>;

/**
 * __useCreateDeveloperBusinessProfileMutation__
 *
 * To run a mutation, you first call `useCreateDeveloperBusinessProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeveloperBusinessProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeveloperBusinessProfileMutation, { data, loading, error }] = useCreateDeveloperBusinessProfileMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      businessName: // value for 'businessName'
 *      businessEmail: // value for 'businessEmail'
 *      businessWebsite: // value for 'businessWebsite'
 *      businessLogo: // value for 'businessLogo'
 *      RcNo: // value for 'RcNo'
 *      about: // value for 'about'
 *      oneId: // value for 'oneId'
 *   },
 * });
 */
export function useCreateDeveloperBusinessProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeveloperBusinessProfileMutation, CreateDeveloperBusinessProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeveloperBusinessProfileMutation, CreateDeveloperBusinessProfileMutationVariables>(CreateDeveloperBusinessProfileDocument, options);
      }
export type CreateDeveloperBusinessProfileMutationHookResult = ReturnType<typeof useCreateDeveloperBusinessProfileMutation>;
export type CreateDeveloperBusinessProfileMutationResult = Apollo.MutationResult<CreateDeveloperBusinessProfileMutation>;
export type CreateDeveloperBusinessProfileMutationOptions = Apollo.BaseMutationOptions<CreateDeveloperBusinessProfileMutation, CreateDeveloperBusinessProfileMutationVariables>;
export const UploadBusinessLogoDocument = gql`
    mutation UploadBusinessLogo($businessLogo: Upload!) {
  setBusinessProfileLogo(businessLogo: $businessLogo)
}
    `;
export type UploadBusinessLogoMutationFn = Apollo.MutationFunction<UploadBusinessLogoMutation, UploadBusinessLogoMutationVariables>;

/**
 * __useUploadBusinessLogoMutation__
 *
 * To run a mutation, you first call `useUploadBusinessLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBusinessLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBusinessLogoMutation, { data, loading, error }] = useUploadBusinessLogoMutation({
 *   variables: {
 *      businessLogo: // value for 'businessLogo'
 *   },
 * });
 */
export function useUploadBusinessLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadBusinessLogoMutation, UploadBusinessLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBusinessLogoMutation, UploadBusinessLogoMutationVariables>(UploadBusinessLogoDocument, options);
      }
export type UploadBusinessLogoMutationHookResult = ReturnType<typeof useUploadBusinessLogoMutation>;
export type UploadBusinessLogoMutationResult = Apollo.MutationResult<UploadBusinessLogoMutation>;
export type UploadBusinessLogoMutationOptions = Apollo.BaseMutationOptions<UploadBusinessLogoMutation, UploadBusinessLogoMutationVariables>;