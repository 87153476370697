import { Base, Guardian, Progress } from "components";
import {
  BUSINESS_PROFILE_URL,
  CREATE_CONSOLE_PROFILE_URL,
  DEVELOPER_PROFILE_URL,
  EDIT_CONSOLE_PROFILE_URL,
  UPDATE_DEVELOPER_PROFILE_URL,
  API_KEY_GENERATED_URL,
  API_KEY_GET_BY_ID,
  API_KEY_LOG,
  BILLING_OVERVIEW_URL,
  COST_SUMMARY_URL,
  INVOICE_HISTORY_URL,
  MAKE_PaYMENT_URL,
  PERFORM_LOOKUP_URL,
} from "helpers";
import { BusinessHome } from "pages/BusinessHome";
import { CreateBusinessProfile } from "pages/Create-Business";
import {
  BusinessProfile,
  DevDashboard,
  DeveloperProfile,
  EditBusinessProfile,
  UpdateProfile,
} from "pages/Developer-Dashboard";
import { ApiKeyGenerated } from "pages/Api-Management/Api-key-Generated";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/* Lazy import pages */
const ApiKeyViewDetails = React.lazy(
  () => import("pages/Api-Management/ApiKeyViewDetails/ApiKeyViewDetails")
);

const ApiLogs = React.lazy(
  () => import("pages/Api-Management/Api-Logs/apiLogs")
);

const BillingOverView = React.lazy(
  () => import("pages/Billing/Billing-Overview/BillingOverview")
);

const InvoiceHistory = React.lazy(
  () => import("pages/Billing/Invoice-History/invoiceHistory")
);

const CostSummary = React.lazy(
  () => import("pages/Billing/Cost-Summary/costSummary")
);

const MakePayment = React.lazy(
  () => import("pages/Billing/Make-Payment/makePayment")
);

const PerformLookup = React.lazy(() => import("pages/Perform-LookUp/Lookup"));

const ComingSoon = React.lazy(() => import("pages/Coming-Soon/ComingSoon"));

const NotFound = React.lazy(() => import("pages/NotFound/index"));

// path={`/dashboard/api-keys/:api-key-id`}

const MainRoutes = () => {
  return (
    <Router>
      <React.Fragment>
        <Routes>
          {/* <Route path="/" element={<BusinessHome />} /> */}

          <Route path="/" element={<Guardian />}>
            <Route
              element={
                <React.Suspense fallback={<p>Loading...</p>}>
                  <Base />
                </React.Suspense>
              }
            >
              {/* Api Management Routes */}
              <Route index element={<DevDashboard />} />
              <Route
                path={API_KEY_GENERATED_URL}
                element={<ApiKeyGenerated />}
              />
              <Route
                path={API_KEY_GET_BY_ID}
                element={
                  <React.Suspense fallback={<Progress />}>
                    <ApiKeyViewDetails />
                  </React.Suspense>
                }
              />
              <Route
                path={API_KEY_LOG}
                element={
                  <React.Suspense fallback={<Progress />}>
                    <ApiLogs />
                  </React.Suspense>
                }
              />

              {/* Billing routes */}
              <Route
                path={BILLING_OVERVIEW_URL}
                element={
                  <React.Suspense fallback={<Progress />}>
                    <BillingOverView />
                  </React.Suspense>
                }
              />
              <Route
                path={INVOICE_HISTORY_URL}
                element={
                  <React.Suspense fallback={<Progress />}>
                    <InvoiceHistory />
                  </React.Suspense>
                }
              />
              <Route
                path={COST_SUMMARY_URL}
                element={
                  <React.Suspense fallback={<Progress />}>
                    <CostSummary />
                  </React.Suspense>
                }
              />

              <Route
                path={MAKE_PaYMENT_URL}
                element={
                  <React.Suspense fallback={<Progress />}>
                    <MakePayment />
                  </React.Suspense>
                }
              />

              <Route
                path={PERFORM_LOOKUP_URL}
                element={
                  <React.Suspense fallback={<Progress />}>
                    <PerformLookup />
                  </React.Suspense>
                }
              />

              <Route
                path={BUSINESS_PROFILE_URL}
                element={<BusinessProfile />}
              />
              <Route
                path={EDIT_CONSOLE_PROFILE_URL}
                element={<EditBusinessProfile />}
              />
              <Route
                path={CREATE_CONSOLE_PROFILE_URL}
                element={<CreateBusinessProfile />}
              />
              <Route
                path={DEVELOPER_PROFILE_URL}
                element={<DeveloperProfile />}
              />
              <Route
                path={UPDATE_DEVELOPER_PROFILE_URL}
                element={<UpdateProfile />}
              />
              <Route
                path={`/coming-soon`}
                element={
                  <React.Suspense fallback={"Loading..."}>
                    <ComingSoon />
                  </React.Suspense>
                }
              />
            </Route>
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Fragment>
    </Router>
  );
};

export default MainRoutes;
