import * as React from "react"
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { getErrorMessage } from "common"
import {
  OIDButton,
  OIDSelect,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components"
import { useFormik } from "formik"
import { useCurrentUser } from "helpers"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useUpdateUserProfileMutation } from "./__types/UpdateUserProfile.types"

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(3).required("Your first name is required"),
  lastName: Yup.string().min(3),
  secondaryEmail: Yup.string().email(),
  gender: Yup.string().oneOf(["male", "female", "others"]),
  dob: Yup.date().nullable(),
  phone: Yup.string().min(10),
})

export const UpdateProfile = () => {
  const navigate = useNavigate()
  const { user, refetch } = useCurrentUser()
  const [updateUserProfile, { loading, error }] = useUpdateUserProfileMutation()

  const initialValues = {
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    gender: user?.gender ?? "",
    dob: user?.dob ?? null,
    phone: user?.phone ?? "",
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        await updateUserProfile({
          variables: values,
        })

        await refetch().then(() => {
          ToastMessage(TOASTER_TYPE.SUCCESS, "Profile updated.")
          navigate("/")
        })
      } catch (e) {
        ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(e))
      }
    },
  })

  if (error) {
    ToastMessage(TOASTER_TYPE.ERROR, error.message)
  }

  return (
    <Grid container>
      {loading && <Progress />}

      <Grid item md={12} lg={6}>
        <Typography variant="h4" mb={4}>
          Update OneID Profile
        </Typography>

        <form onSubmit={handleSubmit}>
          <FormControl sx={{ my: 1 }}>
            <TextField
              required
              fullWidth
              name="firstName"
              label="First Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              placeholder="First Name"
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={
                touched.firstName &&
                Boolean(errors.firstName) &&
                errors.firstName
              }
            />
          </FormControl>

          <FormControl sx={{ my: 1 }}>
            <TextField
              required
              fullWidth
              name="lastName"
              label="Last Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              placeholder="Last Name"
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={
                touched.lastName && Boolean(errors.lastName) && errors.lastName
              }
            />
          </FormControl>

          <FormControl sx={{ my: 1 }}>
            <TextField
              name="phone"
              label="Phone"
              sx={{ pb: 1 }}
              onBlur={handleBlur}
              value={values.phone}
              onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={
                touched.phone && Boolean(errors.phone) && errors.phone
              }
            />
          </FormControl>

          <OIDSelect
            sx={{
              mb: 1,
            }}
            type="text"
            name="gender"
            label="Gender"
            onBlur={handleBlur}
            variant={"outlined"}
            value={values.gender}
            onChange={handleChange}
            selectItems={["male", "female", "others"]}
            error={touched.gender && Boolean(errors.gender)}
            helperText={
              touched.gender && Boolean(errors.gender) && errors.gender
            }
          />

          <Box
            sx={{
              mt: 2,
            }}
          >
            <DatePicker
              disableFuture
              openTo="year"
              value={values.dob}
              label="Date of Birth"
              className="dob__button"
              onChange={newValue => {
                setFieldValue("dob", newValue)
              }}
              views={["year", "month", "day"]}
              renderInput={params => <TextField {...params} />}
            />
          </Box>

          <Box
            sx={{
              mt: 4,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <OIDButton
              text="Update Profile"
              buttonProps={{
                type: "submit",

                sx: theme => ({
                  color: theme.palette.common.white,
                  background: theme.palette.primary.main,
                  "&:hover": {
                    background: theme.palette.primary.light,
                    color: theme.palette.common.white,
                  },
                }),
              }}
            />
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}
