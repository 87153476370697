import { Tooltip, TooltipProps } from "@mui/material";
import * as React from "react";

export const OIDTooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <Tooltip
      {...props}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "#001935",
            color: "#ffffff",
            "& .MuiTooltip-arrow": {
              color: "#001935",
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
