import { Config } from "config"
import { useNativeQueryParams } from "helpers"
import React from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

interface Props {
  children?: React.ReactNode
}

export const Guardian: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const token = localStorage.getItem("1D_AU")
  const auth = useNativeQueryParams().get("auth")
  const refreshToken = useNativeQueryParams().get("rt")
  const isLoggedIn = Boolean(auth) || Boolean(token)

  React.useEffect(() => {
    if (!auth && !token) {
      window.location.href = `${Config.AUTH_GATEWAY_URL}?d=console&r=${pathname}`
    } else if (!token && auth) {
      localStorage.setItem("1D_AU", auth)
      refreshToken && localStorage.setItem("1D_AUR", refreshToken)
      navigate(pathname)
    } else navigate(pathname)
  }, [auth, token, refreshToken, pathname, navigate])

  return isLoggedIn ? (
    <React.Fragment>
      {children}
      <Outlet />
    </React.Fragment>
  ) : null
}
