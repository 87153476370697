import * as React from "react";
import { DeleteApiKey } from "../DeleteApiKey";
import { DialogProps } from "@mui/material/Dialog";
import { StaticDialog } from "components/lib/Modals/StaticDialog";
import { ApiKey } from "types";

interface Props {
  title: string;
  dialogProps: DialogProps;
  data?: ApiKey;
  apiIds?: string[];
  isMultipleApiKeyId?: boolean;
}

export const DeleteApiKeyDialog: React.FC<Props> = ({
  title,
  dialogProps,
  data,
  apiIds,
  isMultipleApiKeyId
}) => {

  return (
    <StaticDialog title={title} dialogProps={dialogProps}>
      <DeleteApiKey
        apiData={data!}
        apiIDs={apiIds!}
        isMultipleApiKeyId={isMultipleApiKeyId}
        handleClose={() => {
          dialogProps.onClose && dialogProps.onClose({}, "backdropClick");
        }}
      />
    </StaticDialog>
  );
};
