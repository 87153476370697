import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateConsoleEnvMutationVariables = Types.Exact<{
  environment: Types.ConsoleEnv;
}>;


export type UpdateConsoleEnvMutation = { __typename?: 'Mutation', updateConsoleEnv?: { __typename?: 'DeveloperConsoleProfile', _id?: string | null, environment?: Types.ConsoleEnv | null, RcNo?: string | null } | null };


export const UpdateConsoleEnvDocument = gql`
    mutation UpdateConsoleEnv($environment: CONSOLE_ENV!) {
  updateConsoleEnv(environment: $environment) {
    _id
    environment
    RcNo
  }
}
    `;
export type UpdateConsoleEnvMutationFn = Apollo.MutationFunction<UpdateConsoleEnvMutation, UpdateConsoleEnvMutationVariables>;

/**
 * __useUpdateConsoleEnvMutation__
 *
 * To run a mutation, you first call `useUpdateConsoleEnvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsoleEnvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsoleEnvMutation, { data, loading, error }] = useUpdateConsoleEnvMutation({
 *   variables: {
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useUpdateConsoleEnvMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsoleEnvMutation, UpdateConsoleEnvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsoleEnvMutation, UpdateConsoleEnvMutationVariables>(UpdateConsoleEnvDocument, options);
      }
export type UpdateConsoleEnvMutationHookResult = ReturnType<typeof useUpdateConsoleEnvMutation>;
export type UpdateConsoleEnvMutationResult = Apollo.MutationResult<UpdateConsoleEnvMutation>;
export type UpdateConsoleEnvMutationOptions = Apollo.BaseMutationOptions<UpdateConsoleEnvMutation, UpdateConsoleEnvMutationVariables>;