import { ApiKeyContext } from "./ApiKeyContext";
import React from "react";
import { Filter, ConsoleEnv } from "types";
import { useCurrentUser } from "../../helpers//lib/useLib.hooks";
import { Progress } from "components";

interface Props {
  children: React.ReactNode;
}
export const ApiKeyProvider: React.FC<Props> = ({ children }) => {
  const { loading, user } = useCurrentUser();
  const [creationCompleted, setCreationCompleted] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [apiKeyId, setApiKeyId] = React.useState<string>();
  const [filters, setFilters] = React.useState<Filter>();
  // Console mode state
  const [envMode, setEnvMode] = React.useState<ConsoleEnv>(
    user?.developerConsoleProfile?.environment || ConsoleEnv.SANDBOX
  );

  /**
   * Waiting to get the user environment details
   *
   * Re-runs with the actual user environment mode
   *
   * fecth correct data after reloading
   *
   */

  React.useEffect(() => {
    if (user && user.developerConsoleProfile) {
      setEnvMode(user?.developerConsoleProfile?.environment as ConsoleEnv);
    }
  }, [user]);

  return (
    <>
      {loading && <Progress />}
      <ApiKeyContext.Provider
        value={{
          filters,
          apiKeyId,
          isEditMode,
          creationCompleted,
          envMode,

          setFilters,
          setApiKeyId,
          setIsEditMode,
          setCreationCompleted,
          setEnvMode,
        }}
      >
        {children}
      </ApiKeyContext.Provider>
    </>
  );
};
