import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDeveloperBusinessProfileMutationVariables = Types.Exact<{
  businessName?: Types.InputMaybe<Types.Scalars['String']>;
  businessEmail?: Types.InputMaybe<Types.Scalars['String']>;
  businessWebsite?: Types.InputMaybe<Types.Scalars['String']>;
  businessLogo?: Types.InputMaybe<Types.Scalars['String']>;
  RcNo?: Types.InputMaybe<Types.Scalars['String']>;
  about?: Types.InputMaybe<Types.Scalars['String']>;
  oneId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateDeveloperBusinessProfileMutation = { __typename?: 'Mutation', updateDeveloperBusinessProfile?: { __typename?: 'DeveloperConsoleProfile', oneId?: string | null, businessName?: string | null, businessEmail?: string | null } | null };


export const UpdateDeveloperBusinessProfileDocument = gql`
    mutation UpdateDeveloperBusinessProfile($businessName: String, $businessEmail: String, $businessWebsite: String, $businessLogo: String, $RcNo: String, $about: String, $oneId: String) {
  updateDeveloperBusinessProfile(
    data: {businessName: $businessName, businessEmail: $businessEmail, businessWebsite: $businessWebsite, businessLogo: $businessLogo, RcNo: $RcNo, about: $about, oneId: $oneId}
  ) {
    oneId
    businessName
    businessEmail
  }
}
    `;
export type UpdateDeveloperBusinessProfileMutationFn = Apollo.MutationFunction<UpdateDeveloperBusinessProfileMutation, UpdateDeveloperBusinessProfileMutationVariables>;

/**
 * __useUpdateDeveloperBusinessProfileMutation__
 *
 * To run a mutation, you first call `useUpdateDeveloperBusinessProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeveloperBusinessProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeveloperBusinessProfileMutation, { data, loading, error }] = useUpdateDeveloperBusinessProfileMutation({
 *   variables: {
 *      businessName: // value for 'businessName'
 *      businessEmail: // value for 'businessEmail'
 *      businessWebsite: // value for 'businessWebsite'
 *      businessLogo: // value for 'businessLogo'
 *      RcNo: // value for 'RcNo'
 *      about: // value for 'about'
 *      oneId: // value for 'oneId'
 *   },
 * });
 */
export function useUpdateDeveloperBusinessProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeveloperBusinessProfileMutation, UpdateDeveloperBusinessProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeveloperBusinessProfileMutation, UpdateDeveloperBusinessProfileMutationVariables>(UpdateDeveloperBusinessProfileDocument, options);
      }
export type UpdateDeveloperBusinessProfileMutationHookResult = ReturnType<typeof useUpdateDeveloperBusinessProfileMutation>;
export type UpdateDeveloperBusinessProfileMutationResult = Apollo.MutationResult<UpdateDeveloperBusinessProfileMutation>;
export type UpdateDeveloperBusinessProfileMutationOptions = Apollo.BaseMutationOptions<UpdateDeveloperBusinessProfileMutation, UpdateDeveloperBusinessProfileMutationVariables>;