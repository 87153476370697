import { Dialog, styled } from "@mui/material"

export const ActionDialogBox = styled(Dialog)(({ theme }) => ({
  "& .dialogTitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .closeButton": {
    "&:hover": {
      background: "rgba(165,36,43,0.92)",
    },
  },
  "& .dialogContent": {},
  "& .dialogActions": {
    "& button:first-child": {
      border: "2px solid rgb(165,36,43)",
      background: "transparent",
      color: "rgb(165,36,43)",
    },
  },
}))
