import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBillPeriodInvoiceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBillPeriodInvoiceQuery = { __typename?: 'Query', getBillPeriodInvoice?: { __typename?: 'Invoice', billIds?: Array<string | null> | null, billingPeriod?: string | null, status?: Types.PaymentStatus | null, amount?: string | null, reference?: string | null, assigned?: string | null } | null };


export const GetBillPeriodInvoiceDocument = gql`
    query GetBillPeriodInvoice {
  getBillPeriodInvoice {
    billIds
    billingPeriod
    status
    amount
    reference
    assigned
  }
}
    `;

/**
 * __useGetBillPeriodInvoiceQuery__
 *
 * To run a query within a React component, call `useGetBillPeriodInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillPeriodInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillPeriodInvoiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillPeriodInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<GetBillPeriodInvoiceQuery, GetBillPeriodInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillPeriodInvoiceQuery, GetBillPeriodInvoiceQueryVariables>(GetBillPeriodInvoiceDocument, options);
      }
export function useGetBillPeriodInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillPeriodInvoiceQuery, GetBillPeriodInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillPeriodInvoiceQuery, GetBillPeriodInvoiceQueryVariables>(GetBillPeriodInvoiceDocument, options);
        }
export type GetBillPeriodInvoiceQueryHookResult = ReturnType<typeof useGetBillPeriodInvoiceQuery>;
export type GetBillPeriodInvoiceLazyQueryHookResult = ReturnType<typeof useGetBillPeriodInvoiceLazyQuery>;
export type GetBillPeriodInvoiceQueryResult = Apollo.QueryResult<GetBillPeriodInvoiceQuery, GetBillPeriodInvoiceQueryVariables>;