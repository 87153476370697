import React from "react"
import "./connectionStatus.style.css"
import NoInternetSVG from "../../../assets/bg/no-internet-connection.svg"

export const ConnectionStatus = () => {
  const reload = () => window.location.reload()

  return (
    <section className="connection_page">
      <div className="details_container">
        <img src={NoInternetSVG} alt="No internet connection" />
        <p className="mb-3">
          Looks like you’ve lost your internet connection. Please check it and
          try again.
        </p>
        <button className="btn od_btn_flat" onClick={reload}>
          Try Again
        </button>
      </div>
    </section>
  )
}
